package com.appcreator.compose.actions.data

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.data.AddListItemAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.actions.Performer

class AddListItemPerformer(private val action: AddListItemAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val inputStore = LocalInputEnvStore.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.values?.let {
                    val listItem = it.values.mapValues { entry ->
                        envStore.injectVariables(entry.value)
                    }
                    action.listPath?.value?.let { key ->
                        inputStore.add(key, listItem)
                    }
                }
            }
        }
    }
}
