package com.appcreator.client.revenuecat

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.revenuecat.RevenuecatHasEntitlementCondition

actual fun HasEntitlementEvaluator.evaluatePerPlatform(
    condition: RevenuecatHasEntitlementCondition,
    envStore: EnvStore
): Boolean = true

actual suspend fun HasEntitlementEvaluator.evaluateAsyncPerPlatform(
    condition: RevenuecatHasEntitlementCondition,
    envStore: EnvStore
): Boolean = true