package com.appcreator.blueprint.actions

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Trigger")
@ActionClass(
    group = "Other",
    title = "Trigger",
    availableFrom = "0.0.1"
)
class TriggerAction(
    @InputProperty(
        title = "Trigger Id",
        availableFrom = "0.0.1"
    )
    val triggerId: String?,
): Action
