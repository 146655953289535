package com.appcreator.blueprint.conditions.logic

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("And")
@ConditionClass(
    group = "Logic",
    title = "And",
    availableFrom = "0.0.1",
    accessible = false
)
data class AndCondition(
    @InputProperty(
        title = "Conditions",
        availableFrom = "0.0.1"
    )
    val conditions: List<Condition>
): Condition
