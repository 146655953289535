package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class DataPath(
    val value: String,
    val source: Source?
) {
    enum class Source {
        Global,
        Local,
        Other
    }
}
