package com.appcreator.client.firebase.auth

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpAction
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.di.Container
import com.appcreator.compose.events.LoginEvent
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth

class SignUpPerformer(val action: FirebaseAuthSignUpAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                val email = action.email?.let { envStore.injectVariables(it) } ?: throw IllegalStateException("No email")
                val password = action.password?.let { envStore.injectVariables(it) } ?: throw IllegalStateException("No password")
                val result = Firebase.auth.createUserWithEmailAndPassword(email, password).user
                Container.sendEvent(LoginEvent(result?.uid, result?.email, result?.displayName))
            }
        }
    }
}