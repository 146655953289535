package com.appcreator.client.contentful

import androidx.compose.runtime.ProvidedValue
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.loaders.Loader
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.url
import io.ktor.http.URLProtocol
import io.ktor.http.Url
import io.ktor.http.buildUrl
import io.ktor.http.path
import kotlinx.datetime.Instant
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.intOrNull
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

data class RichTextInfo(
    val richTextFieldName: String,
    val jsonObject: JsonObject,
    val root: JsonObject
)

class ContentfulLoader(
    private val client: HttpClient,
    private val space: String,
    private val environment: String,
    private val accessToken: String,
    private val spec: ContentfulLoaderSpec
): Loader {

    private val baseUrl = "cdn.contentful.com" // TODO this is not the same in europe

    companion object {
        const val hasMore = "contentful-hasMore"
        const val skipAmount = "contentful-skip"
    }

    override suspend fun load(
        envStore: EnvStore,
        mapping: Map<String, String>,
        setPageLastUpdated: ((Instant) -> Unit)?
    ): Pair<ProvidedValue<*>?, Map<String, Any>> {

        val defaults = spec.parameterSettings?.map { (key, value) -> key to value.default }?.toMap()
            ?: emptyMap()


        val json = client.get {
            url {
                protocol = URLProtocol.HTTPS
                host = baseUrl
                path("spaces", space, "environments", environment, "entries")
                parameters.append("access_token", accessToken)
                spec.modelName?.let {
                    parameters.append("content_type", it)
                }
                spec.modelId?.let {
                    parameters.append("sys.id", envStore.injectValueWithMapping(it, mapping, defaults))
                }?: run {
                    spec.where?.forEach {
                        val value = envStore.injectValueWithMapping(it.value, mapping, defaults)
                        parameters.append("fields.${it.field}", value)
                    }
                    spec.orderBy?.joinToString(separator = ",") {
                        when(it.direction) {
                            ContentfulLoaderSpec.OrderBy.Ascending -> "fields.${it.field}"
                            ContentfulLoaderSpec.OrderBy.Descending -> "-fields.${it.field}"
                        }
                    }?.let {
                        parameters.append("order", it)
                    }
                    spec.limit?.let {
                        parameters.append("limit", it.toString())
                    }

                    envStore.env[skipAmount]?.let {
                        parameters.append("skip", it.toString())
                    }
                }
            }
        }.body<JsonObject>()


        val items = json["items"]?.jsonArray?.map {
            it.jsonObject.decodeItem(json, setPageLastUpdated)
        } ?: emptyList()

        return if (spec.limit == 1 || spec.modelId != null) {
            null to items.first()
        } else {
            val skip = json["skip"]?.jsonPrimitive?.intOrNull?: 0
            val total = json["total"]?.jsonPrimitive?.intOrNull?: 0
            val limit = json["limit"]?.jsonPrimitive?.intOrNull?: 0

            val pagingMap = mapOf<String, Any>(
                hasMore to ((skip + limit) < total),
                skipAmount to (skip + limit)
            )
            null to mapOf((spec.modelName ?: "") to items as Any).plus(pagingMap)
        }
    }

    private fun JsonObject.decodeItem(root: JsonObject, setPageLastUpdated: ((Instant) -> Unit)?): Map<String, Any> {
        get("sys")?.jsonObject?.get("updatedAt")?.jsonPrimitive?.content?.let {
            setPageLastUpdated?.invoke(Instant.parse(it))
        }
        
        val fields = get("fields")!!.jsonObject

        val data: Map<String, Any> = spec.dataValues?.map { value ->
            when (value.type) {
                ContentfulLoaderSpec.ContentfulDataType.Default -> listOf(
                    value.field to (fields[value.field]?.jsonPrimitive?.content ?: "")
                )

                ContentfulLoaderSpec.ContentfulDataType.RichText -> listOf(
                    value.field to RichTextInfo(value.field, fields[value.field]?.jsonObject?: buildJsonObject {}, root)
                )

                ContentfulLoaderSpec.ContentfulDataType.Asset -> fields[value.field]?.extractAsset(
                    "${value.field}-", root) ?: emptyList()

                ContentfulLoaderSpec.ContentfulDataType.Entry -> fields[value.field]?.extractEntry(
                    "${value.field}-", root) ?: emptyList()

                ContentfulLoaderSpec.ContentfulDataType.EntryList -> listOf(value.field to (fields[value.field]?.jsonArray?.map {
                    it.extractEntry("", root).toMap()
                } ?: emptyList()))

                ContentfulLoaderSpec.ContentfulDataType.AssetList -> listOf(value.field to (fields[value.field]?.jsonArray?.map {
                    it.extractAsset("", root).toMap()
                } ?: emptyList()))

                ContentfulLoaderSpec.ContentfulDataType.List -> listOf(value.field to (fields[value.field]?.jsonArray?.map {
                    mapOf(ContentfulLoaderSpec.Keys.listValue to it.jsonPrimitive.content)
                } ?: emptyList()))
            }
        }?.flatten()
            ?.plus(ContentfulLoaderSpec.Keys.entryId to extractId())
            ?.toMap() ?: emptyMap()

        return data
    }

    override fun canLoadMore(data: Map<String, Any>): Boolean = data.get(hasMore) == true

}
private fun JsonElement.extractAsset(prefix: String = "", root: JsonObject): List<Pair<String, Any>> {
    return extractId().extractAsset(prefix, root)
}

internal fun String.extractAsset(prefix: String = "", root: JsonObject): List<Pair<String, Any>> {
    val details = root.findInIncluded(this, "Asset") ?: run {
        return emptyList()
    }

    val fields = details["fields"]!!.jsonObject
    val file = fields["file"]!!.jsonObject

    val fileUrl = "https:${file["url"]?.jsonPrimitive?.content}"
    val fileTitle = fields["title"]?.jsonPrimitive?.content?: ""
    val image = file["details"]?.jsonObject?.get("image")?.jsonObject
    val width = image?.get("width")?.jsonPrimitive?.content?: ""
    val height = image?.get("height")?.jsonPrimitive?.content?: ""
    val contentType = file["contentType"]?.jsonPrimitive?.content ?: ""

    return listOf<Pair<String, Any>>(
        prefix + ContentfulLoaderSpec.Keys.assetId to this,
        prefix + ContentfulLoaderSpec.Keys.fileUrl to fileUrl,
        prefix + ContentfulLoaderSpec.Keys.fileTitle to fileTitle,
        prefix + ContentfulLoaderSpec.Keys.width to width,
        prefix + ContentfulLoaderSpec.Keys.height to height,
        prefix + ContentfulLoaderSpec.Keys.contentType to contentType
    )
}

private fun JsonElement.extractEntry(prefix: String, root: JsonObject): List<Pair<String, Any>> {
    return extractId().extractEntry(prefix, root)
}


internal fun String.extractEntry(prefix: String = "", root: JsonObject): List<Pair<String, Any>> {
    val details = root.findInIncluded(this, "Entry") ?: run { return emptyList() }
    val documentId = details["sys"]?.jsonObject?.get("contentType")?.jsonObject?.get("sys")?.jsonObject?.get("id")?.jsonPrimitive?.content?: ""
    val title = details["fields"]?.jsonObject?.get("title")?.jsonPrimitive?.content ?: ""
    return listOf(
        prefix + ContentfulLoaderSpec.Keys.entryTitle to title,
        prefix + ContentfulLoaderSpec.Keys.entryId to this,
        prefix + ContentfulLoaderSpec.Keys.contentType to documentId
    )
}

private fun JsonElement.findInIncluded(id: String, type: String): JsonObject? =
    jsonObject["includes"]?.jsonObject?.get(type)?.jsonArray?.find {
        it.extractId() == id
    }?.jsonObject

private fun JsonElement.extractId(): String =
    jsonObject["sys"]?.jsonObject?.get("id")?.jsonPrimitive?.content ?: ""
