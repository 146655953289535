package com.appcreator.compose

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import coil3.ImageLoader
import coil3.annotation.ExperimentalCoilApi
import coil3.compose.setSingletonImageLoaderFactory
import coil3.svg.SvgDecoder
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.components.InputEnvStore
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.Setup
import com.appcreator.compose.di.platformGifFactory
import com.appcreator.compose.theme.Theme
import org.jetbrains.compose.resources.FontResource

fun interface BlueprintProvider {
    fun provideBlueprint(): State<Blueprint?>
}

@OptIn(ExperimentalCoilApi::class)
@Composable
fun BlueprintComposable(
    drawableResource: (String) -> String?,
    fontResources: Map<String, FontResource>,
    provider: BlueprintProvider = Setup.blueprintProvider,
    rootScreen: (Blueprint) -> Destination = { Destination(it.rootDestination().screen, emptyMap()) }
) {

    setSingletonImageLoaderFactory {
        ImageLoader.Builder(it)
            // Add a marker value so we know this was created by the default singleton image loader.
            .components {
                add(platformGifFactory())
                add(SvgDecoder.Factory())
            }
            .build()
    }

    val envStore = LocalEnvStore.current
    val nullableBlueprint by remember { provider.provideBlueprint() }
    val permissions by getPermissions()
    nullableBlueprint?.let { blueprint ->
        val globalDefaults = remember {
            (blueprint.globalData
                ?.filter { envStore.env[it.key] == null }
                ?.associate { data ->
                    data.key to data.value(blueprint.defaultEnvironment)
                } ?: emptyMap()
            ) + Container.globalKeys
        }

        permissions?.let {
            val globalState = remember { InputEnvStore(globalDefaults + it, null, false, null) }
            blueprint.Theme {
                CompositionLocalProvider(
                    LocalBlueprint provides blueprint,
                    LocalTheme provides blueprint.theme,
                    LocalInputEnvStore provides globalState,
                    LocalEnvStore provides EnvStore.create(
                        blueprint.defaultLocale,
                        env = globalState.input + envStore.env
                    ),
                    LocalResources provides drawableResource,
                    LocalFontResources provides fontResources
                ) {
                    SnackbarAlertContainer {
                        ScreenComposable(rootScreen(blueprint))
                    }
                }
            }
        }
    }
}

@Composable
expect fun getPermissions(): State<Map<String, String>?>