package com.appcreator.blueprint.components.navigation

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

// TODO I think this is for the bin
@Serializable
@SerialName("NavigationDrawer")
@ComponentClass(
    group = "Navigation",
    title = "Navigation Drawer",
    availableFrom = "0.0.1",
    producesDataFields = "\"drawer-state\"",
    producedFieldsEditable = true,
    accessible = false
)
class NavigationDrawerComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Drawer",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val drawer: Component?,

    @InputProperty(
        title = "Screen",
        availableFrom = "0.0.1"
    )
    val link: BlueprintLink?

): Component