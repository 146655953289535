package com.appcreator.client.firebase.remoteconfig

import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigCondition
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConversion
import com.appcreator.compose.di.Container
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.remoteconfig.remoteConfig
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch

object FirebaseRemoteConfigInitializer {
    fun initialize() {
        GlobalScope.launch {
            // TODO how to make sure properties are loaded in firsst open
            Firebase.remoteConfig.fetchAndActivate()
        }
        Container.registerConversion(FirebaseRemoteConfigConversion::class) {
            RemoteConfigConvertor(it)
        }
        Container.registerCondition(FirebaseRemoteConfigCondition::class) {
            RemoteConfigEvaluator(it)
        }
    }
}

