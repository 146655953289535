package com.appcreator.compose.components.basic

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.toFontFamily
import androidx.compose.ui.text.platform.Font
import org.jetbrains.skiko.loadBytesFromPath

@Composable
actual fun fontFromUrl(url: String): FontFamily? {

    var font by remember { mutableStateOf<FontFamily?>(null) }

    LaunchedEffect(url) {
        try {
            val bytes = loadBytesFromPath(platformCorsWorkAround(url))

            font = Font(
                identity = url,
                data = bytes,
            ).toFontFamily()
        } catch (ex: Exception) {
            ex.printStackTrace()
        }
    }

    return font
}