package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ScriptContent
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("JavascriptDataConversion")
@ConversionClass(
    title = "Javascript",
    availableFrom = "0.0.1"
)
data class JavascriptConversion(
    @InputProperty(
        title = "Script",
        availableFrom = "0.0.1"
    )
    val script: ScriptContent?,
): Conversion