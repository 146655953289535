package com.appcreator.compose.actions.navigation

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.navigation.CloseScreenAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalNavigator
import com.appcreator.compose.actions.Performer

class CloseScreenPerformer(private val action: CloseScreenAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val navigator = LocalNavigator.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                when(action.closeType) {
                    CloseScreenAction.CloseType.Count ->
                        action.count?.let {
                            navigator?.back(it)
                        }
                    CloseScreenAction.CloseType.ToRoot -> navigator?.popToRoot()
                    CloseScreenAction.CloseType.AllModal -> navigator?.closeModal()
                    else -> navigator?.back()
                }
            }
        }
    }

}