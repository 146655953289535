package com.appcreator.compose.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.remember
import com.appcreator.blueprint.core.matches
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.di.Setup
import com.russhwolf.settings.Settings
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonArray

class InputEnvStore(
    initial: Map<String, Any>,
    private val key: String?,
    private val persistent: Boolean,
    private val parent: InputEnvStore?
) {

    private val settings = Settings()
    private val _input: MutableMap<String, Any> = if(persistent) {
        val restored: Map<String, Any> = key?.let {
            val json = settings.getString(it, "{}")
            Setup.json.decodeFromString<JsonElement>(json).decode()
        }?: emptyMap()

        val restoredMap = initial.map {
            it.key to (restored[it.key] ?: it.value)
        }
        mutableStateMapOf(*restoredMap.toTypedArray())
    } else {
        mutableStateMapOf(*initial.toList().toTypedArray())
    }

    val input: Map<String, Any> = _input

    fun set(key: String, value: Any) {
        if(_input.contains(key)) {
            _input[key] = value
            sync()
        } else {
            parent?.set(key, value)?: println("No store for key $key")
        }
    }

    fun add(key: String, value: Any) {
        if(_input.contains(key)) {
            (_input[key] as? List<Any>)?.let { list ->
                _input[key] = list.plus(value)
                sync()
            }
        } else {
            parent?.add(key, value)?: println("No store for key $key")
        }
    }

    fun remove(key: String, toRemove: Map<String, String>) {
        if(_input.contains(key)) {
            (_input[key] as? List<Map<String, Any>>)?.let { list ->
                _input[key] = list.filterNot { current ->
                    toRemove matches current
                }
                sync()
            }
        } else {
            parent?.remove(key, toRemove)?: println("No store for key $key")
        }
    }

    private fun sync() {
        if (persistent) {
            key?.let {
                val data = input.encode().toString()
                println("Sync $data")
                settings.putString(key, data)
            }
        }
    }
}

@Composable
fun InputEnvComposable(
    initial: Map<String, Any>,
    key: String? = null,
    persistent: Boolean = false,
    content: @Composable () -> Unit
) {
    val parent = LocalInputEnvStore.current
    val inputEnvStore = remember { InputEnvStore(initial, key, persistent, parent) }
    CompositionLocalProvider(LocalInputEnvStore provides inputEnvStore) {
        EnvComposable(inputEnvStore.input, content = content)
    }
}

private fun Map<String, Any>.encode(): JsonElement {
    return buildJsonObject {
        map {
            when (it.value) {
                is List<*> -> putJsonArray(it.key) {
                    (it.value as? List<Map<String, Any>>)?.let { list ->
                        list.forEach { item ->
                            add(item.encode())
                        }
                    }
                }

                else -> put(it.key, it.value.toString())
            }
        }
    }
}

private fun JsonElement.decode(into: MutableMap<String, Any> = mutableMapOf()): Map<String, Any> {
    when(this) {
        is JsonObject -> {
            entries.forEach {
                when(it.value) {
                    is JsonPrimitive -> into[it.key] = (it.value as JsonPrimitive).content
                    is JsonArray -> into[it.key] = (it.value as JsonArray).map { item -> item.decode() }
                    else -> into[it.key] = it.value.decode()
                }
            }
        }
        else -> {}
    }
    return into
}