import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.material3.Card
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.CanvasBasedWindow
import com.appcreator.BuildConfig
import com.appcreator.MyApp
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.blueprintSerializer
import com.appcreator.blueprint.components.Screen
import com.appcreator.client.contentful.ContentfulInitializer
import com.appcreator.client.firebase.analytics.FirebaseAnalyticsInitializer
import com.appcreator.client.firebase.auth.FirebaseAuthInitializer
import com.appcreator.client.firebase.firestore.FirebaseFirestoreInitializer
import com.appcreator.client.firebase.messaging.FirebaseMessagingInitializer
import com.appcreator.client.firebase.remoteconfig.FirebaseRemoteConfigInitializer
import com.appcreator.client.firebase.storage.FirebaseStorageInitializer
import com.appcreator.compose.BlueprintProvider
import com.appcreator.compose.di.Setup
import com.appcreator.compose.extensions.JSEvaluationConfig
import com.appcreator.compose.loaders.ScreenFetcher
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.dto.BlueprintResponse
import com.appcreator.dto.ScreenResponse
import com.appcreator.dto.configurations.ContentfulConfiguration
import com.appcreator.dto.configurations.FirebaseConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfigurationTypes
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.http.HttpHeaders
import io.ktor.http.HttpStatusCode
import io.ktor.http.appendPathSegments
import io.ktor.serialization.kotlinx.json.json
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.modules.SerializersModule
import org.jetbrains.compose.resources.configureWebResources
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.url.URLSearchParams

class AuthApi(
    private val token: String,
    private val client: HttpClient,
) {
    suspend fun getBlueprint(): BlueprintResponse =
        client.get {
            url { appendPathSegments("preview", "blueprint") }
            authorize()
        }.body()

    suspend fun getScreen(id: String): ScreenResponse =
        client.get {
            url { appendPathSegments("preview", "blueprint", "screen", id) }
            authorize()
        }.body()

    private fun HttpRequestBuilder.authorize() {
        header(HttpHeaders.Authorization, "Bearer $token")
    }
}

class Fetcher(
    private val authApi: AuthApi,
    private val blueprint: Blueprint
): ScreenFetcher, BlueprintProvider {

    var invalidSession: Boolean by mutableStateOf(false)
        private set

    override suspend fun fetch(id: String): State<Screen> {
        try {
            return mutableStateOf(authApi.getScreen(id).screen)
        } catch (ex: Exception) {
            if ((ex as? ClientRequestException)?.response?.status == HttpStatusCode.Unauthorized) {
                invalidSession = true
            }
            throw ex
        }
    }

    override fun provideBlueprint(): State<Blueprint?> =
        mutableStateOf(blueprint)

}

fun main() {

    val json = Json {
        serializersModule = SerializersModule {
            blueprintSerializer()()
        }
        ignoreUnknownKeys = true
        encodeDefaults = true
        explicitNulls = false
        isLenient = true
    }
    val httpClient = HttpClient {
        install(ContentNegotiation) { json(json) }
        defaultRequest {
            url(BuildConfig.BASE_URL)
        }
        expectSuccess = true
    }

    val origin = window.location.origin
    configureWebResources {
        resourcePathMapping { "$origin/$it" }
    }

    GlobalScope.launch {

        val token = URLSearchParams(window.location.search).get("token") ?: run {
            window.location.replace("https://app.yourappcreator.com")
            return@launch
        }

        val api = AuthApi(token, httpClient)

        try {
            val blueprintResponse = api.getBlueprint()
            withContext(Dispatchers.Main) {
                val fetcher = Fetcher(api, blueprintResponse.blueprint)
                Setup.initialize(
                    screenFetcher = { fetcher },
                    blueprintProvider = { fetcher },
                    jsEvaluationConfig = JSEvaluationConfig(),
                    developmentMode = false
                )

                setupFirebase(json, blueprintResponse)
                setupContentful(httpClient, json, blueprintResponse)

                startApp {
                    if(fetcher.invalidSession) {
                        InvalidSession()
                    } else {
                        MyApp()
                    }
                }
            }
        } catch (ex: Exception) {
            if ((ex as? ClientRequestException)?.response?.status == HttpStatusCode.Unauthorized) {
                startApp { InvalidSession() }
            } else {
                startApp { LoadingError() }
            }
        }
    }

}

private fun startApp(content: @Composable () -> Unit) {
    onWasmReady {
        CanvasBasedWindow("Your app creator") {
            content()
        }
    }
}

private suspend fun setupFirebase(
    json: Json,
    blueprintResponse: BlueprintResponse,
) {

    blueprintResponse.thirdPartyConfiguration.find { it.type == ThirdPartyConfigurationTypes.firebase }?.config?.let {

        val config: FirebaseConfiguration = json.decodeFromJsonElement(it)
        Firebase.initialize(options = FirebaseOptions(
            apiKey = config.webConfig.apiKey,
            authDomain = config.webConfig.authDomain,
            projectId = config.webConfig.projectId,
            storageBucket = config.webConfig.storageBucket,
            gcmSenderId = config.webConfig.messagingSenderId,
            applicationId = config.webConfig.appId,
            gaTrackingId = config.webConfig.measurementId,
        ))

        FirebaseAnalyticsInitializer.initialize()
        FirebaseAuthInitializer.initialize()
        FirebaseMessagingInitializer.initialize()
        FirebaseFirestoreInitializer.initialize()

        FirebaseRemoteConfigInitializer.initialize()
        FirebaseStorageInitializer.initialize()
    }
}

private fun setupContentful(
    client: HttpClient,
    json: Json,
    blueprintResponse: BlueprintResponse,
) {
    blueprintResponse.thirdPartyConfiguration.find { it.type == ThirdPartyConfigurationTypes.contentful }?.config?.let {
        val config: ContentfulConfiguration = json.decodeFromJsonElement(it)
        ContentfulInitializer.initialize(client, config.space, config.environment, config.accessToken)
    }
}

@Composable
private fun InvalidSession() {
    ErrorScreen {
        Text(
            text = "Session expired",
            style = MaterialTheme.typography.headlineMedium
        )
        Text(
            text = "Start a new preview session in via the editor."
        )

    }
}

@Composable
private fun LoadingError() {
    ErrorScreen {
        Text(
            text = "Oops something went wrong!",
            style = MaterialTheme.typography.headlineMedium
        )
        Text(
            text = "Try refreshing or start a new preview session in via the editor."
        )
    }
}

@Composable
private fun ErrorScreen(content: @Composable () -> Unit) {
    Box(modifier = Modifier.fillMaxSize().tracingPaperBackground(), contentAlignment = Alignment.Center) {
        Card(Modifier.padding(25.dp)) {
            Column(Modifier.padding(25.dp), horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.spacedBy(26.dp)) {
                content()
            }
        }
    }
}