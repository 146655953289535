package com.appcreator.compose

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.ScreenSpec
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.components.data.LoadingComponent
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponent
import com.appcreator.blueprint.core.LoaderConfig
import com.appcreator.blueprint.core.LoaderSpecReference
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.EnvComposable
import com.appcreator.compose.components.data.LoadingComposable
import com.appcreator.compose.components.navigation.Navigation
import com.appcreator.compose.components.navigation.rememberNavigator
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import com.appcreator.compose.extensions.value

@Composable
fun ScreenComposable(destination: Destination) {
    EnvComposable(destination.properties) {
        LoadingComposable(
            Modifier,
            LoadingComponent(
                _nodeId = null,
                _nodeLabel = null,
                _nodeRelativeId = null,
                config = LoaderConfig(
                    loaderSpec = LoaderSpecReference("screen-loader"),
                    parameters = mapOf("id" to destination.screen.id),
                    supportsPaging = false
                ),
                content = destination.screen,
                action = null,
                pullToRefresh = false,
                errorView = LoadingComponent.ViewType.Default,
                errorContent = null,
                loadingView = LoadingComponent.ViewType.Default,
                loadingContent = null,
                reloadTrigger = null,
                displayInPreview = null
            )
        )
    }
}

@Composable
fun ScreenSpecComposable(modifier: Modifier, screenSpec: ScreenSpec) {
    val screen by LocalScreen.current
    ScreenComposable(modifier, screen)
}

@Composable
fun ScreenComposable(modifier: Modifier, screen: Screen) {
    when(screen.screenType) {
        Screen.ScreenType.Navigation -> ScreenNavigationComposable(modifier, screen)
        else -> ScreenContentComposable(modifier, screen)
    }
    screen.onLoadAction?.let {
        val deferred = Container.performer(it)?.deferred()
        val env = LocalEnvStore.current
        LaunchedEffect(Unit) {
            deferred?.perform(env)
        }
    }
}


@Composable
private fun ScreenNavigationComposable(modifier: Modifier, screen: Screen) {
//    Surface(modifier.fillMaxSize()) {
        screen.navigation?.let {
            ComponentComposable(Modifier, it)
        }
//    }
}

@Composable
private fun ScreenContentComposable(modifier: Modifier, screen: Screen) {

    val navigator = LocalNavigator.current?: return
    val willAddNavigation = screen.content is NavigationDrawerComponent
    val startPath = LocalEnvStore.current.injectVariables(screen.path?.value ?: "")

    ScreenComposableContent(modifier, screen, navigator, !willAddNavigation)

    val analytics = LocalAnalytics.current
    var screenTitle by LocalScreenTitle.current
    val title = screen.title?.value()
    LaunchedEffect(title) {
        analytics.screenView(startPath, title)
        screenTitle = title ?: ""
    }
    val inPreview = LocalInPreview.current
    if(!inPreview) {
        val updater = platformTitleUpdater()
        LaunchedEffect(screenTitle) {
            updater(screenTitle)
        }
    }
}

@Composable
expect fun platformTitleUpdater(): (String) -> Unit

@Composable
expect fun ScreenComposableContent(
    modifier: Modifier,
    screen: Screen,
    navigator: Navigator,
    includeToolbar: Boolean
)