package com.appcreator.blueprint.components.logic

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@SerialName("AB")
@Serializable
@ComponentClass(
    group = "Logic",
    title = "Conditional Switch",
    availableFrom = "0.0.1"
)
data class ABComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Condition",
        description = "If the condition is true Content A will be shown otherwise Content B",
        availableFrom = "0.0.1"
    )
    val condition: Condition?,
    @InputProperty(
        title = "Content A",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val contentA: Component?,
    @InputProperty(
        title = "Content B",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val contentB: Component?,

    @InputProperty(
        section = "Preview",
        title = "Show in preview",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ABComponent.PreviewComponent.ContentA")
    val previewComponent: PreviewComponent?,
): Component {


    enum class PreviewComponent {
        ContentA,
        ContentB
    }

}
