package com.appcreator.client.firebase.firestore

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.TypedDataValue
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataAction
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.extensions.parseToInstant
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.firestore.CollectionReference
import dev.gitlive.firebase.firestore.DocumentReference
import dev.gitlive.firebase.firestore.Timestamp
import dev.gitlive.firebase.firestore.firestore
import kotlinx.datetime.Instant

class SetDataPerformer(private val action: FirebaseFirestoreSetDataAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.path?.let { path ->

                    val injectedPath = envStore.injectVariables(path)
                    val pathRef = when(val reference = injectedPath.pathToReferences()) {
                        is PathReference.Collection -> {
                            reference.ref.document(getRandomString())
                        }
                        is PathReference.Document -> {
                            reference.ref
                        }
                    }

                    action.data
                        ?.mapNotNull {
                            val injected = envStore.injectVariables(it.value)
                            val typed: Any? = when(it.type) {
                                TypedDataValue.Type.String -> injected
                                TypedDataValue.Type.Number -> injected.toIntOrNull()
                                TypedDataValue.Type.Boolean -> injected.toBooleanStrictOrNull()
                                TypedDataValue.Type.Timestamp -> {
                                    val time = injected.parseToInstant()
                                    Timestamp(time.epochSeconds, 0)
                                }
                                TypedDataValue.Type.StringArray -> { injected.split(",") }
                            }
                            typed?.let { value ->
                                it.key to value
                            }
                        }?.toMap()?.let {
                            pathRef.set(it, merge = true)
                        }
                }
            }
        }
    }
}

private fun getRandomString(length: Int = 12) : String {
    val allowedChars = ('A'..'Z') + ('a'..'z')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}