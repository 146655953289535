package com.appcreator.compose.components.navigation

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.consumeWindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.width
import androidx.compose.material3.DrawerDefaults
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.NavigationBarDefaults
import androidx.compose.material3.NavigationBarItemDefaults
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.NavigationRailDefaults
import androidx.compose.material3.NavigationRailItemDefaults
import androidx.compose.material3.PermanentNavigationDrawer
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.ImageComponent
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.blueprint.components.navigation.NavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationItemComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.core.properties.SizeValue
import com.appcreator.compose.KotlinXNavigator
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalNavigator
import com.appcreator.compose.LocalSelected
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.Navigator
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.basic.ImageComposable
import com.appcreator.compose.components.basic.MaterialIconComposable
import com.appcreator.compose.components.basic.toTextStyle
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.value


@Composable
fun NavigationComposable(modifier: Modifier, component: NavigationComponent) {

    val envStore = LocalEnvStore.current
    val start = ((component.tabBarTabs.firstOrNull() as? NavigationItemComponent)?.link ?: component.rootScreen)?.let {
        envStore.injectLink(it)
    }?: "screen"

    val navigator = rememberNavigator(true, start)

    val content: @Composable () -> Unit = {
        Box(modifier) {
            if(component.tabBar == true){
                AdaptiveBottomNavigation(component, navigator)
            } else {
                Navigation(
                    modifier = Modifier,
                    navigator = navigator,
                )
            }
        }
    }

    if (component.navigationDrawer ==  true) {
        BoxWithConstraints {
            val adaptive = component.drawerAdaptiveOption?: NavigationComponent.DrawerAdaptiveOption.PermanentIfSpace

            when {
                maxWidth > 1200.dp && adaptive == NavigationComponent.DrawerAdaptiveOption.PermanentIfSpace ->
                    PermanentNavigationDrawer(
                        drawerContent = {
                            CompositionLocalProvider(LocalNavigator provides navigator) {
                                Surface(
                                    Modifier.width(
                                        (component.navigationDrawerWidth ?: 300).dp
                                    )
                                ) {
                                    component.drawerContent?.let {
                                        ComponentComposable(Modifier, it)
                                    }
                                }
                            }
                        },
                        content = content
                    )
                else -> NavigationDrawer(
                    showDrawerInPreview = component.showNavigationDrawer == true,
                    navigator = navigator,
                    content = content,
                    drawerWidth = component.navigationDrawerWidth ?: 300,
                    drawer = {
                        CompositionLocalProvider(LocalNavigator provides navigator) {
                            Surface(modifier.fillMaxSize()) {
                                component.drawerContent?.let {
                                    ComponentComposable(Modifier, it)
                                }
                            }
                        }
                    }
                )
            }
        }
    } else {
        content()
    }
}

@Composable
private fun AdaptiveBottomNavigation(component: NavigationComponent, navigator: Navigator) {

    val envStore = LocalEnvStore.current
    val theme = LocalTheme.current
    val color = theme?.color(component.tabBarBackgroundColor)?.composeColor() ?: MaterialTheme.colorScheme.surface
    val items = component.tabBarTabs.filterIsInstance<NavigationItemComponent>()
    val selected = items.indexOfFirst { bottomNavigationSelected(navigator, it.link, envStore) }

    val selectedColor = theme?.color(component.tabBarSelectedColor)?.composeColor() ?: Color.Unspecified
    val unSelectedColor = theme?.color(component.tabBarUnselectedColor)?.composeColor() ?: Color.Unspecified
    val indicatorColor = theme?.color(component.tabBarIndicatorColor)?.composeColor() ?: MaterialTheme.colorScheme.secondaryContainer

    BoxWithConstraints {
        val adaptive = component.tabBarAdaptiveOption?: NavigationComponent.TabBarAdaptiveOption.NavigationRailExpandedIfSpace
        val layoutType = when {
            maxWidth > 1600.dp && adaptive >= NavigationComponent.TabBarAdaptiveOption.NavigationRailExpandedIfSpace
            -> NavigationSuiteType.NavigationDrawer
            maxWidth > 800.dp && adaptive >= NavigationComponent.TabBarAdaptiveOption.NavigationRailIfSpace
            -> NavigationSuiteType.NavigationRail
            else -> NavigationSuiteType.NavigationBar
        }

        val colors = NavigationSuiteDefaults.itemColors(
            navigationBarItemColors = NavigationBarItemDefaults.colors(
                selectedIconColor = selectedColor,
                selectedTextColor = selectedColor,
                unselectedTextColor = unSelectedColor,
                unselectedIconColor = unSelectedColor,
                indicatorColor = indicatorColor,
            ),
            navigationRailItemColors = NavigationRailItemDefaults.colors(
                selectedIconColor = selectedColor,
                selectedTextColor = selectedColor,
                unselectedTextColor = unSelectedColor,
                unselectedIconColor = unSelectedColor,
                indicatorColor = indicatorColor
            ),
            navigationDrawerItemColors = NavigationDrawerItemDefaults.colors(
                selectedIconColor = selectedColor,
                selectedTextColor = selectedColor,
                unselectedTextColor = unSelectedColor,
                unselectedIconColor = unSelectedColor,
                selectedContainerColor = indicatorColor
            )
        )

        NavigationSuiteScaffoldLayout(
            navigationSuite = {
                NavigationSuite(
                    layoutType = layoutType,
                    topPadding = component.navigationRailTopPadding?.dp?: 0.dp,
                    colors = NavigationSuiteDefaults.colors(
                        navigationBarContainerColor = color,
                        navigationDrawerContainerColor = color,
                        navigationRailContainerColor = color,
                    ),
                    content = {
                        items.forEachIndexed { index, it ->
                            val isSelected = index == selected
                            item(
                                selected = isSelected,
                                icon = {
                                    when(it.itemType) {
                                        NavigationItemComponent.ItemType.Image ->
                                            ImageComposable(Modifier, ImageComponent(
                                                    _nodeId = null,
                                                    _nodeLabel = null,
                                                    _nodeRelativeId = null,
                                                    source = ImageComponent.Source.ImageCenter,
                                                    imageResource = it.image,
                                                    url = null,
                                                    width = SizeValue.Px(28),
                                                    height = SizeValue.Px(28),
                                                    loading = null,
                                                    fallback = null,
                                                    scale = ImageComponent.Scale.Inside
                                                ),
                                                if(it.maskImage == false) null
                                                else ColorFilter.tint(if(isSelected) selectedColor else unSelectedColor)
                                            )
                                        else -> MaterialIconComposable(Modifier, MaterialIconComponent(
                                            _nodeId = null,
                                            _nodeLabel = null,
                                            _nodeRelativeId = null,
                                            icon = it.materialIcon,
                                            size = 24,
                                            color = if (isSelected) component.tabBarSelectedColor else component.tabBarUnselectedColor
                                        ))
                                    }
                                },
                                label = {
                                    CompositionLocalProvider(LocalSelected provides isSelected) {
                                        it.title?.value()?.let { value ->
                                            CompositionLocalProvider(
                                                LocalDensity provides Density(
                                                    LocalDensity.current.density, 1f
                                                )
                                            ) {
                                                val font = component.tabBarLabelSize.toTextStyle()
                                                Text(
                                                    color = LocalContentColor.current,
                                                    text = value,
                                                    style = font,
                                                    softWrap = false
                                                )
                                            }
                                        }
                                    }
                                },
                                colors = colors,
                                alwaysShowLabel = component.tabBarAlwaysShowLabels == true,
                                onClick = {
                                    it.link?.let { link ->
                                        navigator.navigate(link, envStore) {
                                            popUpToRoot = true
//                                            singleTop = true
                                        }
                                    }
                                }
                            )
                        }
                    }
                )
            },
            layoutType = layoutType,
            content = {
                Box(
                    Modifier.consumeWindowInsets(
                        when (layoutType) {
                            NavigationSuiteType.NavigationBar ->
                                NavigationBarDefaults.windowInsets
                            NavigationSuiteType.NavigationRail ->
                                NavigationRailDefaults.windowInsets
                            NavigationSuiteType.NavigationDrawer ->
                                DrawerDefaults.windowInsets
                            else -> WindowInsets(0, 0, 0, 0)
                        }
                    )
                ) {
                    Navigation(
                        modifier = Modifier,
                        navigator = navigator,
                    )
                }
            }
        )
    }

}

@Composable
private fun bottomNavigationSelected(
    navigator: Navigator?,
    link: BlueprintLink?,
    envStore: EnvStore
): Boolean = (navigator as? KotlinXNavigator)?.inStack(link, envStore) == true