package com.appcreator.client.contentful.richtext

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.unit.dp


@Composable
internal fun TableItem(
    node: RichNode.Table,
    context: Context,
    openLink: (String) -> Unit,
    openEntry: (String) -> Unit
) {

    val borderColor = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.6f)
    Column(
        modifier = Modifier.fillMaxWidth()
            .border(
                width = 1.dp,
                color = borderColor,
                shape = RoundedCornerShape(10.dp)
            )
            .clip(RoundedCornerShape(10.dp))
    ) {
        node.content
            .filterIsInstance<RichNode.TableRow>()
            .forEachIndexed { index, row ->
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .drawWithCache {
                            onDrawBehind {
                                val step = (size.width / row.content.size).toInt()
                                for (i in step..<size.width.toInt() step step) {
                                    drawLine(
                                        color = borderColor,
                                        start = Offset(i.toFloat(), 0f),
                                        end = Offset(i.toFloat(), size.height)
                                    )
                                }
                            }
                        },
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    row.content.forEach {
                        val modifer = when (it) {
                            is RichNode.TableHeaderCell -> Modifier.background(MaterialTheme.colorScheme.onBackground.copy(alpha = 0.3f))
                            else -> Modifier
                        }

                        Box(modifier = modifer
                            .weight(1f)
                            .padding(12.dp)) {

                            TextItem(
                                node = it,
                                padding = 0.dp,
                                context = context,
                                openLink = openLink,
                                openEntry = openEntry
                            )
                        }
                    }
                }
                if (index < node.content.lastIndex) {
                    HorizontalDivider(color = borderColor)
                }
            }
    }
    Spacer(Modifier.height(16.dp))
}

