package com.appcreator.blueprint.revenuecat

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("RevenuecatManageSubscriptionsAction")
@ActionClass(
    group = RevenuecatConsts.group,
    title = "Manage Subscriptions",
    availableFrom = "0.0.1",
    isExtraPlugin = true
)
class RevenuecatManageSubscriptionsAction: Action