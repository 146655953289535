package com.appcreator.compose.conditions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.conditions.JavascriptCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.extensions.JSEvaluationConfig
import com.appcreator.compose.extensions.evaluateJavaScript
import com.appcreator.compose.extensions.evaluateJavaScriptSync

class JavascriptEvaluator(
    private val config: JSEvaluationConfig,
    internal val condition: JavascriptCondition,
): Evaluator {

    @Composable
    override fun evaluate(envStore: EnvStore): Boolean? {
        return platformEvaluate(envStore)
    }

    override suspend fun evaluateAsync(envStore: EnvStore): Boolean {
        return condition.scriptContent?.script?.let {
            val result = evaluateJavaScript(config, envStore.injectVariables(it))
            result == "true"
        }?: false
    }

    override fun evaluateOnce(envStore: EnvStore): Boolean {
        return condition.scriptContent?.script?.let {
            val result = evaluateJavaScriptSync(envStore.injectVariables(it))
            result == "true"
        }?: false
    }

}

@Composable
expect fun JavascriptEvaluator.platformEvaluate(envStore: EnvStore): Boolean?
