package com.appcreator.compose.components.basic

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.FlowColumn
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListScope
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.ProvidableCompositionLocal
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.compositeOver
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.layout.layoutId
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.util.fastForEachReversed
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.components.data.DataRepeaterComponent
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.ComponentPosition
import com.appcreator.blueprint.core.properties.ComponentPosition.XAlign.*
import com.appcreator.compose.LocalAnalytics
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalHorizontalScrollingParent
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.LocalVerticalScrollingParent
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.data.dataRepeaterComposable
import com.appcreator.compose.components.data.rememberRepeaterItems
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import com.appcreator.compose.extensions.SizingView
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.toPadding
import com.appcreator.compose.extensions.toShape
import com.valentinilk.shimmer.shimmer
import kotlinx.coroutines.launch
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.unit.LayoutDirection

@Composable
fun ContainerComposable(modifier: Modifier, component: ContainerComponent) {
    val theme = LocalTheme.current

    val padding = component.padding?.toPadding() ?: PaddingValues()
    val margin = component.margin?.toPadding() ?: PaddingValues()
    val spaceBy = ((component.spacingValue?: 0) * component.content.count() - 1).coerceAtLeast(0)
    val verticalSpacing = if(component.axis == ContainerComponent.Axis.Y && component.spacing == ContainerComponent.Spacing.ByValue){
        spaceBy
    } else 0
    val horizontalSpacing = if(component.axis == ContainerComponent.Axis.X && component.spacing == ContainerComponent.Spacing.ByValue){
        spaceBy
    } else 0

    SizingView(
        component.width,
        component.height,
        component.minWidth,
        component.maxWidth,
        component.minHeight,
        component.maxHeight,
        horizontalSpacing,
        verticalSpacing,
        component.aspectRatio,
        padding, margin
    ) { sizingMod, _, _ ->
        val updatedModifier = sizingMod.then(modifier)
            .let { mod ->
                component.margin?.let { mod.padding(margin) } ?: mod
            }
            .let { mod ->
                if (component.shimmer == true) mod.shimmer()
                else mod
            }
            .let { mod ->
                component.borderRadius?.toShape()?.let { mod.clip(it) } ?: mod
            }
            .let { mod ->
                when(component.backgroundType) {
                    ContainerComponent.Background.Color -> component.backgroundColor?.let { ref ->
                        theme?.color(ref)?.let { color ->
                            val alpha = component.opacity?.toFloatOrNull()?: 1f
                            val composeColor = color.composeColor()
                            if (component.gradient == true) {
                                val brush = remember {
                                    val black = Color.Black
                                    val white = Color.White
                                    val overlay = composeColor.copy(alpha = 0.9f)
                                    val start = overlay.compositeOver(white)
                                    val end = overlay.compositeOver(black)
                                    Brush.verticalGradient(listOf(start, end))
                                }
                                mod.background(brush = brush)
                            } else {
                                mod.background(composeColor.copy(alpha = alpha))
                            }
                        }
                    } ?: mod
                    ContainerComponent.Background.Image -> mod.backgroundImage(component.backgroundImage, component.opacity)
                    else -> mod
                }
            }.let { mod ->
                val borderRadius = component.borderRadius
                val borderThickness = component.borderThickness ?: 0
                val borderColor =
                    theme?.color(component.borderColor)?.composeColor() ?: Color.Unspecified
                mod.border(
                    borderThickness.dp,
                    borderColor,
                    borderRadius?.toShape() ?: RoundedCornerShape(0.dp)
                )
            }.let { mod ->
                component.action?.let {
                    val deferred = Container.performer(it)?.deferred()
                    val envStore = LocalEnvStore.current
                    val analytics = LocalAnalytics.current
                    val scope = rememberCoroutineScope()
                    val buttonName = component.analyticsButtonName?.let { eventName -> envStore.injectVariables(eventName) }
                    mod.clickable {
                        scope.launch {
                            buttonName?.let { name ->
                                analytics.event("click", mapOf("button_name" to name))
                            }
                            try {
                                deferred?.perform(envStore)
                            } catch (ex: Exception) {
                                ex.printStackTrace()
                                // TODO handle error
                            }
                        }
                    }
                } ?: mod
            }.let { mod ->
                component.viewOpacity?.toFloatOrNull()?.let {
                    mod.alpha(it)
                }?: mod
            }

        val paddingValues = component.padding?.toPadding() ?: PaddingValues(0.dp)
        if (component.positioning == ContainerComponent.Positioning.Exact) {
            ExactLayout(updatedModifier.padding(paddingValues), component)

        } else {

            when (component.axis) {
                ContainerComponent.Axis.X -> RowInternal(
                    modifier = updatedModifier,
                    paddingValues = paddingValues,
                    spaceBy = horizontalSpacing.dp,
                    scrollable = component.scrolling,
                    flow = component.overflow ?: false,
                    keepOffScreenViews = component.keepOffScreenViewsAlive == true,
                    horizontalArrangement = when (component.spacing) {
                        ContainerComponent.Spacing.ByValue -> Arrangement.spacedBy(
                            (component.spacingValue ?: 0).dp
                        )

                        ContainerComponent.Spacing.Between -> Arrangement.SpaceBetween
                        ContainerComponent.Spacing.Around -> Arrangement.SpaceAround
                        else -> when (component.horizontalAlignment) {
                            ContainerComponent.HorizontalAlignment.Center -> Arrangement.Center
                            ContainerComponent.HorizontalAlignment.End -> Arrangement.End
                            else -> Arrangement.Start
                        }
                    },
                    verticalAlignment = when (component.verticalAlignment) {
                        ContainerComponent.VerticalAlignment.Center -> Alignment.CenterVertically
                        ContainerComponent.VerticalAlignment.Bottom -> Alignment.Bottom
                        else -> Alignment.Top
                    },
                    layoutPriority = component.layoutPriority ?: ContainerComponent.LayoutPriority.LeftToRight,
                    content = component.content
                )

                ContainerComponent.Axis.Y -> ColumnInternal(
                    modifier = updatedModifier,
                    paddingValues = paddingValues,
                    spaceBy = verticalSpacing.dp,
                    scrollable = component.scrolling,
                    flow = component.overflow ?: false,
                    keepOffScreenViews = component.keepOffScreenViewsAlive == true,
                    horizontalAlignment = when (component.horizontalAlignment) {
                        ContainerComponent.HorizontalAlignment.Center -> Alignment.CenterHorizontally
                        ContainerComponent.HorizontalAlignment.End -> Alignment.End
                        else -> Alignment.Start
                    },
                    verticalArrangement = when (component.spacing) {
                        ContainerComponent.Spacing.ByValue -> Arrangement.spacedBy(
                            (component.spacingValue ?: 0).dp
                        )

                        ContainerComponent.Spacing.Between -> Arrangement.SpaceBetween
                        ContainerComponent.Spacing.Around -> Arrangement.SpaceAround
                        else -> when (component.verticalAlignment) {
                            ContainerComponent.VerticalAlignment.Center -> Arrangement.Center
                            ContainerComponent.VerticalAlignment.Bottom -> Arrangement.Bottom
                            else -> Arrangement.Top
                        }
                    },
                    content = component.content
                )

                ContainerComponent.Axis.Z -> Box(
                    modifier = updatedModifier.padding(paddingValues),
                    contentAlignment = when (component.verticalAlignment) {
                        ContainerComponent.VerticalAlignment.Center -> when (component.horizontalAlignment) {
                            ContainerComponent.HorizontalAlignment.Center -> Alignment.Center
                            ContainerComponent.HorizontalAlignment.End -> Alignment.CenterEnd
                            else -> Alignment.CenterStart
                        }

                        ContainerComponent.VerticalAlignment.Bottom -> when (component.horizontalAlignment) {
                            ContainerComponent.HorizontalAlignment.Center -> Alignment.BottomCenter
                            ContainerComponent.HorizontalAlignment.End -> Alignment.BottomEnd
                            else -> Alignment.BottomEnd
                        }

                        else -> when (component.horizontalAlignment) {
                            ContainerComponent.HorizontalAlignment.Center -> Alignment.TopCenter
                            ContainerComponent.HorizontalAlignment.End -> Alignment.TopEnd
                            else -> Alignment.TopStart
                        }
                    }
                ) {
                    component.content.forEach {
                        ComponentComposable(Modifier, it)
                    }
                }
            }
        }
    }
}

@Composable
private fun RowInternal(
    modifier: Modifier,
    paddingValues: PaddingValues,
    spaceBy: Dp,
    scrollable: Boolean,
    flow: Boolean,
    keepOffScreenViews: Boolean,
    horizontalArrangement: Arrangement.Horizontal,
    verticalAlignment: Alignment.Vertical,
    layoutPriority: ContainerComponent.LayoutPriority,
    content: List<Component>
) {
    Internal(
        modifier = modifier,
        paddingValues = paddingValues,
        scrollable = scrollable,
        flow = flow,
        keepOffScreenViews = keepOffScreenViews,
        content = content,
        parentScrolling = LocalHorizontalScrollingParent,
        lazy = { mod, body ->
            LazyRow(
                modifier = mod,
                horizontalArrangement = horizontalArrangement,
                verticalAlignment = verticalAlignment,
                contentPadding = paddingValues,
                content = body
            )
        },
        scrollingLayout = { mod, body ->
            Row(
                modifier = mod.horizontalScroll(rememberScrollState()),
                horizontalArrangement = horizontalArrangement,
                verticalAlignment = verticalAlignment
            ) {
                body()
            }
        },
        layout = { mod, body ->
            when(layoutPriority) {
                ContainerComponent.LayoutPriority.LeftToRight -> Row(
                    modifier = mod,
                    horizontalArrangement = horizontalArrangement,
                    verticalAlignment = verticalAlignment
                ) {
                    body(false)
                }
                ContainerComponent.LayoutPriority.RightToLeft ->
                    CompositionLocalProvider(LocalLayoutDirection provides LayoutDirection.Rtl) {
                        Row(
                            modifier = mod,
                            horizontalArrangement = horizontalArrangement,
                            verticalAlignment = verticalAlignment
                        ) {
                            CompositionLocalProvider(LocalLayoutDirection provides LayoutDirection.Ltr) {
                                body(true)
                            }
                        }
                    }
            }
        },
        inverseAxisParentScrolling = LocalVerticalScrollingParent,
        scrollingFlowLayout = { mod , body ->
            FlowRow(
                modifier = mod.horizontalScroll(rememberScrollState()),
                verticalArrangement = Arrangement.spacedBy(spaceBy, verticalAlignment),
                horizontalArrangement = horizontalArrangement,
            ) {
                body()
            }
        },
        flowLayout = { mod, body ->
            FlowRow(
                modifier = mod,
                verticalArrangement = Arrangement.spacedBy(spaceBy, verticalAlignment),
                horizontalArrangement = horizontalArrangement,
            ) {
                body()
            }
        }
    )
}

@Composable
private fun ColumnInternal(
    modifier: Modifier,
    paddingValues: PaddingValues,
    spaceBy: Dp,
    scrollable: Boolean,
    flow: Boolean,
    keepOffScreenViews: Boolean,
    verticalArrangement: Arrangement.Vertical,
    horizontalAlignment: Alignment.Horizontal,
    content: List<Component>
) {
    Internal(
        modifier = modifier,
        paddingValues = paddingValues,
        scrollable = scrollable,
        flow = flow,
        keepOffScreenViews = keepOffScreenViews,
        content = content,
        parentScrolling = LocalVerticalScrollingParent,
        lazy = { mod, body ->
            LazyColumn(
                modifier = mod,
                verticalArrangement = verticalArrangement,
                horizontalAlignment = horizontalAlignment,
                contentPadding = paddingValues,
                content = body
            )
        },
        scrollingLayout = { mod, body ->
            Column(
                modifier = mod.verticalScroll(rememberScrollState()),
                verticalArrangement = verticalArrangement,
                horizontalAlignment = horizontalAlignment
            ) {
                body()
            }
        },
        layout = { mod, body ->
            Column(
                modifier = mod,
                verticalArrangement = verticalArrangement,
                horizontalAlignment = horizontalAlignment
            ) {
                body(false)
            }
        },
        inverseAxisParentScrolling = LocalHorizontalScrollingParent,
        scrollingFlowLayout = { mod , body ->
            FlowColumn(
                modifier = mod.horizontalScroll(rememberScrollState()),
                verticalArrangement = verticalArrangement,
                horizontalArrangement = Arrangement.spacedBy(space = spaceBy, horizontalAlignment),
            ) {
                body()
            }
        },
        flowLayout = { mod, body ->
            FlowColumn(
                modifier = mod,
                verticalArrangement = verticalArrangement,
                horizontalArrangement = Arrangement.spacedBy(space = spaceBy, horizontalAlignment),
            ) {
                body()
            }
        }
    )
}

@Composable
private fun Internal(
    modifier: Modifier,
    paddingValues: PaddingValues,
    scrollable: Boolean,
    flow: Boolean,
    keepOffScreenViews: Boolean,
    content: List<Component>,

    parentScrolling: ProvidableCompositionLocal<Boolean>,
    lazy: @Composable (Modifier, LazyListScope.() -> Unit) -> Unit,
    scrollingLayout: @Composable (Modifier, @Composable () -> Unit) -> Unit,
    layout: @Composable (Modifier, @Composable (Boolean) -> Unit) -> Unit,

    inverseAxisParentScrolling: ProvidableCompositionLocal<Boolean>,
    scrollingFlowLayout: @Composable (Modifier, @Composable () -> Unit) -> Unit,
    flowLayout: @Composable (Modifier, @Composable () -> Unit) -> Unit,
) {
    if(flow) {

        val shouldScroll = scrollable && !inverseAxisParentScrolling.current
        if(shouldScroll) {
            CompositionLocalProvider(inverseAxisParentScrolling provides true) {
                scrollingFlowLayout(modifier.padding(paddingValues)) {
                    content.forEach {
                        ComponentComposable(Modifier, it)
                    }
                }
            }
        } else {
            flowLayout(modifier.padding(paddingValues)) {
                content.forEach {
                    ComponentComposable(Modifier, it)
                }
            }
        }

    } else {

        val shouldScroll = scrollable && !parentScrolling.current
        if (shouldScroll && !keepOffScreenViews) {
            val envStore = LocalEnvStore.current
            val repeaters = remember { mutableStateMapOf<String, List<EnvStore>?>() }
            content.forEach {
                if (it is DataRepeaterComponent) {
                    it._nodeId?.let { nodeId ->
                        repeaters[nodeId] = rememberRepeaterItems(envStore, it)
                    }
                }
            }
            CompositionLocalProvider(parentScrolling provides true) {
                lazy(modifier) {
                    content.forEach {
                        if (it is DataRepeaterComponent) {
                            dataRepeaterComposable(it, repeaters[it._nodeId])
                        } else {
                            item {
                                ComponentComposable(Modifier, it)
                            }
                        }
                    }
                }
            }
        } else if (shouldScroll) {
            CompositionLocalProvider(parentScrolling provides true) {
                scrollingLayout(
                    modifier.padding(paddingValues)
                ) {
                    content.forEach {
                        ComponentComposable(Modifier, it)
                    }
                }
            }
        } else {
            layout(modifier.padding(paddingValues)) { reverse ->
                if (reverse) {
                    content.fastForEachReversed {
                        ComponentComposable(Modifier, it)
                    }
                } else {
                    content.forEach {
                        ComponentComposable(Modifier, it)
                    }
                }
            }
        }
    }
}

fun Modifier.positioned(componentPosition: ComponentPosition) = layoutId(componentPosition)

@Composable
private fun ExactLayout(modifier: Modifier, component: ContainerComponent) {

    Box(modifier) {
        Layout(
//        modifier = modifier,
            content = {
                component.content.forEach { child ->
                    component.exactPositions?.find { it.positionOf == child._nodeRelativeId }?.let { pos ->
                        Box(Modifier.positioned(pos)) {
                            ComponentComposable(Modifier, child)
                        }
                    }?: ComponentComposable(Modifier, child)
                }
            }
        ) { measurables, constraints ->
            // Measure all children
            val childConstraints = constraints.copy(minWidth = 0, minHeight = 0)
            val placeables = measurables.map { measurable ->
                measurable.measure(childConstraints) to measurable.layoutId
            }

            // The size of the layout is the full available space
            layout(constraints.maxWidth, constraints.maxHeight) {
                placeables.forEach { (placeable, pos) ->
                    // Get x, y for each child
                    val (x, y) = (pos as? ComponentPosition)?.let { position ->
                        val x = (constraints.maxWidth.toFloat() * (position.x ?: 0f))
                        val y = (constraints.maxHeight.toFloat() * (position.y ?: 0f))

                        val subtractX = when (pos.xAlign) {
                            Start -> 0
                            Center -> placeable.width / 2
                            End -> placeable.width
                        }

                        val subtractY = when (pos.yAlign) {
                            ComponentPosition.YAlign.Top -> 0
                            ComponentPosition.YAlign.Center -> placeable.height / 2
                            ComponentPosition.YAlign.Bottom -> placeable.height
                        }

                        (x.toInt() - subtractX) to (y.toInt() - subtractY)
                    } ?: (0 to 0)

                    placeable.place(x, y)
                }
            }
        }
    }
}