package com.appcreator.compose.components

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.Component
import com.appcreator.compose.di.Container

@Composable
actual fun ComponentComposable(modifier: Modifier, component: Component) {
//    val selected = LocalSelectedNode.current == component._nodeId
//
//    var targetColor: Color by remember { mutableStateOf(Color.Transparent) }
//    LaunchedEffect(selected) {
//        if (selected) {
//            targetColor = Color.Cyan
//            delay(2000)
//            targetColor = Color.Transparent
//        }
//    }
//
//    val color by animateColorAsState(targetColor, animationSpec = tween(1000))
//    val modifier = if (selected) modifier.background(color) else modifier


    val modifierProvider = LocalComponentModifierProvider.current
//
////    var hovering by remember { mutableStateOf(false) }
//    val mod2 = modifier
//        .then(modifierProvider.provideModifier())
////        .onPointerEvent(PointerEventType.Enter) {
////            hovering = true
////        }.onPointerEvent(PointerEventType.Exit) {
////            hovering = false
////        }
////        .border(2.dp, color = if (hovering) Color.Red else Color.Unspecified)


    modifierProvider.Wrap(modifier, component) {
        Container.componentRegistry[component::class]?.invoke(it, component) ?: run {
            println("----- No component registered for ${component::class} -----")
        }
    }
}
