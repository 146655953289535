package com.appcreator.compose.gif

import coil3.ImageLoader
import coil3.decode.DecodeResult
import coil3.decode.Decoder
import coil3.decode.ImageSource
import coil3.fetch.SourceFetchResult
import coil3.request.Options
import okio.BufferedSource
import okio.ByteString.Companion.encodeUtf8
import org.jetbrains.skia.Codec
import org.jetbrains.skia.Data

class GifDecoderFactor: Decoder.Factory {
    override fun create(
        result: SourceFetchResult,
        options: Options,
        imageLoader: ImageLoader
    ): Decoder? {
        return if (!isGif(result.source.source())) null
        else GifDecoder(result.source, options)
    }
}

private val GIF_HEADER_87A = "GIF87a".encodeUtf8()
private val GIF_HEADER_89A = "GIF89a".encodeUtf8()

private fun isGif(source: BufferedSource): Boolean {
    return source.rangeEquals(0, GIF_HEADER_89A) ||
            source.rangeEquals(0, GIF_HEADER_87A)
}

class GifDecoder(
    private val source: ImageSource,
    private val options: Options,
): Decoder {

    override suspend fun decode(): DecodeResult? {
        val bytes = source.source().readByteArray()
        return try {
            val data = Data.makeFromBytes(bytes)
            val codec = Codec.makeFromData(data)
            val image = AnimatedImageImpl(codec)
            DecodeResult(
                image = image,
                isSampled = false,
            )
        } catch (t: Throwable) {
            throw throw IllegalArgumentException(
                "Failed to decode ${bytes.size} bytes to a bitmap. Decoded bytes:\n${
                    bytes.slice(0 until 1024).toByteArray().decodeToString()
                }\n",
                t
            )
        }
    }

}