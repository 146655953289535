package com.appcreator.compose.components.pager

import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.pager.TabbedPageComponent
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.value

@Composable
fun TabbedPageComposableTab(
    modifier: Modifier,
    selected: Boolean,
    component: TabbedPageComponent
) {
    when (component.titleStyle) {
        TabbedPageComponent.TitleStyle.Custom -> if (selected && component.selectedTitleContent != null) {
            ComponentComposable(modifier, component.selectedTitleContent!!)
        } else {
            component.titleContent?.let {
                ComponentComposable(modifier, it)
            }
        }

        else -> component.title?.let {
            Text(it.value(), modifier)
        }
    }
}

@Composable
fun TabbedPageComposableContent(modifier: Modifier, component: TabbedPageComponent) {
    component.content?.let {
        ComponentComposable(modifier, it)
    }
}