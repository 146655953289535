package com.appcreator.blueprint.actions.alerts

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ColorReference
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("SnackbarAlert")
@ActionClass(
    group = "Alerts",
    title = "Snackbar Alert",
    availableFrom = "0.0.1",
)
class SnackbarAlertAction(
    @InputProperty(
        title = "Message",
        availableFrom = "0.0.1"
    )
    val message: String?,
    @InputProperty(
        title = "Text Color",
        availableFrom = "0.0.1"
    )
    val textColor: ColorReference?,
    @InputProperty(
        title = "Background Color",
        availableFrom = "0.0.1"
    )
    val backgroundColor: ColorReference?,

    @InputProperty(
        title = "Alignment",
        availableFrom = "0.0.1"
    )
    @GenericDefault("SnackbarAlertAction.Alignment.Bottom")
    val alignment: Alignment?,
): Action {

    enum class Alignment {
        Top,
        Bottom
    }

}