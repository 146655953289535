package com.appcreator.client.firebase.firestore

import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreDeleteDataAction
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataAction
import com.appcreator.compose.di.Container

object FirebaseFirestoreInitializer {
    fun initialize() {
        Container.registerLoader(FirebaseFirestoreLoaderSpec::class) {
            FirestoreLoader(it)
        }
        Container.registerAction(FirebaseFirestoreSetDataAction::class) {
            SetDataPerformer(it)
        }
        Container.registerAction(FirebaseFirestoreDeleteDataAction::class) {
            DeleteDataPerformer(it)
        }

    }
}

