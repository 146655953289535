package com.appcreator.compose.actions.data

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.data.SetListAction
import com.appcreator.blueprint.actions.data.SetValueAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.actions.Performer

class SetListPerformer(private val action: SetListAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val inputStore = LocalInputEnvStore.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.key?.let { setValue ->
                    action.value?.value?.let {
                        envStore.get(it)?.let { list ->
                            if (list is List<*>) {
                                inputStore.set(setValue.value, list)
                            }
                        }
                    }
                }
            }
        }
    }
}
