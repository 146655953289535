package com.appcreator.blueprint.conditions.permission

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("HasPermission")
@ConditionClass(
    group = "Other",
    title = "Check Permission",
    availableFrom = "0.0.1",
    accessible = true
)
class CheckPermissionCondition(
    @InputProperty(
        title = "Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("CheckPermissionCondition.Permission.Notifications")
    val permission: Permission?,

    @InputProperty(
        title = "State",
        availableFrom = "0.0.1"
    )
    @GenericDefault("CheckPermissionCondition.State.NotDetermined")
    val state: State?

): Condition {

    enum class Permission {
        Notifications,
        Location
    }

    enum class State {
        NotDetermined,
        Granted,
        Denied,
    }
}
