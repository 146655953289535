package com.appcreator.blueprint.actions

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Alert")
@ActionClass(
    group = "",
    title = "Alert",
    availableFrom = "0.0.1",
    accessible = false
)
class AlertAction(
    @InputProperty(
        title = "Title",
        availableFrom = "0.0.1"
    )
    val title: String?,
    @InputProperty(
        title = "Message",
        availableFrom = "0.0.1"
    )
    val message: String?,
    @InputProperty(
        title = "Button",
        availableFrom = "0.0.1"
    )
    val button: String?,
): Action
