package com.appcreator.compose.actions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.ConditionAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator
import com.appcreator.compose.di.performer

class ConditionPerformer(private val action: ConditionAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val evaluator = action.condition?.let { Container.evaluator(it) }
        val deferredSuccess = action.successAction?.let { Container.performer(it)?.deferred() }
        val deferredFailure = action.failureAction?.let { Container.performer(it)?.deferred() }
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                if(evaluator?.evaluateAsync(envStore) == true) {
                    deferredSuccess?.perform(envStore)
                } else {
                    deferredFailure?.perform(envStore)
                }
            }
        }
    }
}