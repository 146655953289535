package com.appcreator.blueprint.dataspecs

import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.DataSpecClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Value")
@DataSpecClass(
    title = "Value",
    availableFrom = "0.0.1",
)
data class ValueDataSpec(
    @InputProperty(
        title = "Key",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    override val key: String,

    @InputProperty(
        title = "Value Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ValueDataSpec.Type.String")
    val valueType: Type?,

    @InputProperty(
        title = "Preview Value",
        availableFrom = "0.0.1"
    )
    val value: String?,

    @InputProperty(
        title = "Visible",
        availableFrom = "0.0.1",
    )
    val visible: Boolean?,

    override val specType: String = DataSpecType.Value

): DataSpec {

    enum class Type {
        String,
        Number,
        Boolean,
        Timestamp
    }

    override fun copySpec(key: String): DataSpec = copy(key = key)

}