package com.appcreator.client.contentful.richtext

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import com.appcreator.client.contentful.RichTextInfo

enum class OrderedListFormat(
    val next: OrderedListFormat?
) {
    Lowercase(null),
    Roman(Lowercase),
    Capital(Roman),
    Number(Capital),
}

enum class UnorderedListFormat(
    val next: UnorderedListFormat?
) {
    Square(null),
    HollowCircle(Square),
    Circle(HollowCircle),
}

internal data class Context(
    val typography: Typography,
    val linkColor: Color,
    val inheritedFormat: InheritedFormat,
    val orderedListFormat: OrderedListFormat = OrderedListFormat.Number,
    val unorderedListFormat: UnorderedListFormat = UnorderedListFormat.Circle
)

internal sealed class InheritedFormat {
    data object None : InheritedFormat()
    data object Quote : InheritedFormat()
}

@Composable
fun RichTextRenderer(
    modifier: Modifier = Modifier,
    richText: RichTextInfo,
    typography: Typography = MaterialTheme.typography,
    linkColor: Color = MaterialTheme.colorScheme.primary,
    openLink: (String) -> Unit,
    openEntry: (String) -> Unit
) {
    val context = Context(typography, linkColor, InheritedFormat.None)
    val rootNode = remember { resolveRichNode(richText) }
    SelectionContainer {
        Column(
            modifier = modifier,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            rootNode.content.forEach {
                CreateItem(
                    node = it,
                    info = richText,
                    context = context,
                    openLink = openLink,
                    openEntry = openEntry
                )
            }
        }
    }
}


@Composable
private fun CreateItem(
    node: RichNode,
    info: RichTextInfo,
    context: Context,
    openLink: (String) -> Unit,
    openEntry: (String) -> Unit
) {
    when (node) {
        is RichNode.EmbeddedAssetBlock -> EmbeddedAsset(node, info)
        is RichNode.EmbeddedEntryBlock -> EmbeddedEntry(node, info)
        is RichNode.Hr -> HorizontalDivider(modifier = Modifier.padding(vertical = 12.dp))
        is RichNode.OrderedList -> CreateListItem(true, node, info, context, openLink, openEntry)
        is RichNode.UnorderedList -> CreateListItem(false, node, info, context, openLink, openEntry)
        is RichNode.Quote -> QuoteItem(node, info, context, openLink, openEntry)
        is RichNode.Table -> TableItem(node, context, openLink, openEntry)
        else -> TextItem(
            node = node,
            context = context,
            openLink = openLink,
            openEntry = openEntry
        )
    }
}

@Composable
private fun CreateListItem(
    ordered: Boolean,
    node: RichBlock,
    info: RichTextInfo,
    context: Context,
    openLink: (String) -> Unit,
    openEntry: (String) -> Unit,
) {
    node.content.forEachIndexed { index, childNode ->
        Row {

            val minSize = 24.dp * LocalDensity.current.fontScale
            if (ordered) {

                val value = when (context.orderedListFormat) {
                    OrderedListFormat.Lowercase -> ('a' + index).toString()
                    OrderedListFormat.Roman -> intToRoman(index + 1).lowercase()
                    OrderedListFormat.Capital -> ('A' + index).toString()
                    OrderedListFormat.Number -> (index + 1).toString()
                }
                Text(
                    modifier = Modifier.sizeIn(minWidth = minSize, minHeight = minSize).padding(top = 2.dp),
                    color = MaterialTheme.colorScheme.onSurface,
                    style = context.typography.bodyMedium,
                    text = "$value. "
                )

            } else {

                val shape = when(context.unorderedListFormat) {
                    UnorderedListFormat.Square -> Modifier
                        .size(6.dp)
                        .background(color = MaterialTheme.colorScheme.onBackground)
                    UnorderedListFormat.HollowCircle -> Modifier
                        .size(8.dp)
                        .border(width = 1.dp, color = MaterialTheme.colorScheme.onBackground, shape = CircleShape)
                    UnorderedListFormat.Circle -> Modifier
                        .size(6.dp)
                        .background(color = MaterialTheme.colorScheme.onBackground, shape = CircleShape)
                }

                Box(Modifier.size(minSize), contentAlignment = Alignment.Center) {
                    Box(shape)
                }
            }

            val nextContext = if(ordered) {
                context.copy(
                    orderedListFormat = context.orderedListFormat.next?: context.orderedListFormat,
                    unorderedListFormat = UnorderedListFormat.Circle
                )
            } else {
                context.copy(
                    orderedListFormat = OrderedListFormat.Number,
                    unorderedListFormat = context.unorderedListFormat.next?: context.unorderedListFormat,
                )
            }

            when(childNode) {
                is RichNode.ListItem -> {
                    Column {
                        childNode.content.forEach { childChildNode ->
                            CreateItem(
                                node = childChildNode,
                                info = info,
                                context = nextContext,
                                openLink = openLink,
                                openEntry = openEntry
                            )
                        }
                    }
                }
                else -> {
                    // Don't think this should ever be hit
                    TextItem(
                        node = childNode,
                        padding = 8.dp,
                        context = context,
                        openLink = openLink,
                        openEntry = openEntry
                    )
                }
            }



        }
        if (index == node.content.lastIndex) {
            Spacer(modifier = Modifier.height(16.dp))
        }
    }
}

@Composable
internal fun QuoteItem(
    node: RichNode.Quote,
    info: RichTextInfo,
    context: Context,
    openLink: (String) -> Unit,
    openEntry: (String) -> Unit
) {
    val color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.5f)
    Column(modifier = Modifier.drawWithCache {
        onDrawBehind {
            drawLine(
                color = color,
                start = Offset.Zero,
                end = Offset(0f, size.height - 16.dp.toPx()),
                strokeWidth = 4.dp.toPx()
            )
        }
    }
        .padding(start = 24.dp)
        .padding(vertical = 4.dp)
    ) {
        node.content.forEach {
            CreateItem(
                node = it,
                info = info,
                context = context,
                openLink = openLink,
                openEntry = openEntry
            )
        }
    }
}

private val m_k = listOf(1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1)
private val m_v = listOf("M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I")

private fun intToRoman(num: Int): String {
    var str = ""
    var n = num

    for (i in m_k.indices) {
        while (n >= m_k[i]) {
            n -= m_k[i]
            str += m_v[i]
        }
    }
    return str
}