package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("DateDataConversion")
@ConversionClass(
    title = "Date Formatter",
    availableFrom = "0.0.1"
)
data class DateFormatConversion(
    @InputProperty(
        title = "To format",
        availableFrom = "0.0.1"
    )
    val datePath: DataPath?,
): Conversion