package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.properties.MaterialIcon
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("MaterialIcon")
@ComponentClass(
    group = "Basic",
    title = "Icon",
    availableFrom = "0.0.1"
)
data class MaterialIconComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Code",
        availableFrom = "0.0.1"
    )
    val icon: MaterialIcon?,
    @InputProperty(
        title = "Size",
        availableFrom = "0.0.1"
    )
    @GenericDefault("24")
    val size: Int,

    @InputProperty(
        title = "Color",
        availableFrom = "0.0.1"
    )
    val color: ColorReference?,

    ): Component