package com.appcreator.compose.components.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.data.DataConversionComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.EnvComposable
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.convertor

@Composable
fun DataConversionComposable(modifier: Modifier, component: DataConversionComponent) {

    val envStore = LocalEnvStore.current
    var converted by remember { mutableStateOf(
        try {
            component.conversion?.let { Container.convertor(it)?.convert(envStore, null) }
        } catch (ex: Exception) { null }
    ) }

    LaunchedEffect(envStore.env) {
        Container.jsConfig?.let { config ->
            try {
                converted = component.conversion?.let { Container.convertor(it)?.convert(config, envStore, null) }
            } catch (ex: Exception) {
                ex.printStackTrace()
            }
        }
    }

    component.content?.let {
        if (converted != null && component.outputPath != null) {
            EnvComposable(mapOf(component.outputPath!! to converted!!)) {
                ComponentComposable(modifier, it)
            }
        } else {
            ComponentComposable(modifier, it)
        }
    }
}
