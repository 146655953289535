package com.appcreator.client.contentful

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.contentful.ContentfulRichTextComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.client.contentful.richtext.RichTextRenderer
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonElement

@Composable
fun RichTextComposable(modifier: Modifier, component: ContentfulRichTextComponent) {
    val env = LocalEnvStore.current

    component.richText?.let {
        (env.get(it.value) as? RichTextInfo)?.let { info ->

            val openLinkAction = component.onExternalLinkClick?.let { action ->
                Container.performer(action)?.deferred()
            }
            val openEntryAction = component.onEntryLinkClick?.let { action ->
                Container.performer(action)?.deferred()
            }
            val scope = rememberCoroutineScope()
            RichTextRenderer(
                modifier = modifier,
                richText = info,
                openLink = { link ->
                    scope.launch {
                        val withLink = EnvStore.create(env, mapOf(ContentfulRichTextComponent.Keys.externalLink to link))
                        openLinkAction?.perform(withLink)
                    }
                },
                openEntry = { entryId ->
                    val entryDetails = entryId.extractEntry(root = info.root).toMap()
                    println(entryDetails)
                    scope.launch {
                        val withLink = EnvStore.create(env, entryDetails)
                        openEntryAction?.perform(withLink)
                    }
                }
            )
        }
    }
}
