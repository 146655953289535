package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class Font(
    val id: String,
    val name: String,
    val fontSize: Int,
    val fontWeight: Int,
    val userCreated: Boolean?,
    val customFont: CustomFont?,
    val lineHeight: Int?,
)

@Serializable
data class FontReference(
    val id: String?,
    val custom: Font?
)

@Serializable
data class CustomFont(
    val id: String,
    val name: String,
    val extension: String?,
    val url: String
)