package com.appcreator.blueprint.loaderspec

import com.appcreator.blueprint.core.DataShape
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.core.UrlSpec
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ScreenLoaderSpec")
data class ScreenLoaderSpec(
    override val id: String,
    override val parameters: Set<String> = emptySet(),
    val urlSpec: UrlSpec = UrlSpec(""),
    override val dataShape: DataShape? = null,
    override val parameterSettings: Map<String, LoaderSpec.ParameterSetting>? = null,
): LoaderSpec {
    override val supportsPaging: Boolean
        get() = false
}
