package com.appcreator.compose.loaders

import androidx.compose.runtime.ProvidedValue
import androidx.compose.runtime.State
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.loaderspec.ScreenLoaderSpec
import com.appcreator.compose.LocalScreen
import kotlinx.datetime.Instant

interface ScreenFetcher {
    suspend fun fetch(id: String): State<Screen>
}

class ScreenLoader(
    private val fetcher: ScreenFetcher,
    private val loaderSpec: ScreenLoaderSpec,
): Loader {

    override suspend fun load(
        envStore: EnvStore,
        mapping: Map<String, String>,
        setPageLastUpdated: ((Instant) -> Unit)?
    ): Pair<ProvidedValue<*>?, Map<String, Any>> {
        val screen = fetcher.fetch(mapping["id"]?: throw IllegalStateException("No screen ID provided"))
        screen.value.lastUpdated?.let { setPageLastUpdated?.invoke(Instant.parse(it)) }
        return LocalScreen provides screen to emptyMap()
    }

    override fun canLoadMore(data: Map<String, Any>): Boolean = false

}