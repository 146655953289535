package com.appcreator.compose.components.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.data.DatePartsComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.EnvComposable
import com.appcreator.compose.extensions.createDateParts

@Composable
fun DatePartsComposable(modifier: Modifier, component: DatePartsComponent) {

    val envStore = LocalEnvStore.current
    val dateString = component.date?.let { envStore.injectVariables(it) }

    val parts = remember(dateString) { createDateParts(dateString) }

    component.content?.let { content ->
        EnvComposable(parts) {
            ComponentComposable(modifier, content)
        }
    }

}