package com.appcreator.blueprint.core.properties

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
sealed class SizeValue(@Transient open val value: Int = 0) {
    @Serializable
    @SerialName("px")
    data class Px(override val value: Int): SizeValue(value) {
        override fun stringValue(): String = value.toString()
    }
    @Serializable
    @SerialName("percent")
    data class Percent(override val value: Int): SizeValue(value) {
        override fun stringValue(): String = "$value%"
    }

    companion object {
        fun fromString(raw: String?): SizeValue? =
            raw?.let {
                if (raw.endsWith("%")) {
                    val number = raw.trimEnd('%')
                    number.toIntOrNull()?.let { Percent(it) }
                } else {
                    raw.toIntOrNull()?.let { Px(it) }
                }
            }
    }

    abstract fun stringValue(): String
}
