package com.appcreator.blueprint.actions.navigation

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("SetNavigationDrawer")
@ActionClass(
    group = "Navigation",
    title = "Configure Navigation Drawer",
    availableFrom = "0.0.1"
)
class SetNavigationDrawerAction(
    @InputProperty(
        title = "Open/Close Navigation Drawer",
        availableFrom = "0.0.1"
    )
    @GenericDefault("SetNavigationDrawerAction.Action.Closed")
    val drawerAction: Action?,
): Action {

    enum class Action {
        Open,
        Closed
    }

}
