package com.appcreator.compose.conditions.data

import com.appcreator.blueprint.conditions.data.ContainsItemCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator

class ContainsItemEvaluator(private val condition: ContainsItemCondition): Evaluator {

    override fun evaluateOnce(envStore: EnvStore): Boolean {
        condition.values?.let {
            val listItem = it.values.mapValues { entry ->
                envStore.injectVariables(entry.value)
            }
            condition.listPath?.value?.let { key ->
                return envStore.listContains(key, listItem)
            }
        }
        return false
    }

}
