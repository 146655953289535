package com.appcreator.compose.actions.data

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.data.ApiRequestAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer

class ApiRequestPerformer(private val action: ApiRequestAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val blueprint = LocalBlueprint.current
        val deferred = action.subAction?.let { Container.performer(it)?.deferred() }
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.config?.let { config ->
                    val loaderSpec = blueprint.loaderSpec(config.loaderSpec)?: run {
                        println("----- No loader spec found for ${config.loaderSpec.id} -----")
                        return@let
                    }
                    Container.loaderRegistry[loaderSpec::class]?.let {
                        try {
                            val (_, updatedEnv) = it(loaderSpec).load(envStore, config.parameters, null)
                            deferred?.perform(EnvStore.create(envStore, updatedEnv))
                        } catch (ex: Exception) {
                            ex.printStackTrace()
                            // TODO allow chaining failure actions
                            throw ex
                        }
                    } ?: run {
                        println("----- No loader spec registered for ${config.loaderSpec::class} -----")
                    }
                }
            }
        }
    }
}
