package com.appcreator.client.firebase.auth

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoginAction
import com.appcreator.compose.actions.Performer
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth

class LoginPerformer(val action: FirebaseAuthLoginAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                val email = action.email?.let { envStore.injectVariables(it) }
                    ?: throw IllegalStateException("No email")
                val password = action.password?.let { envStore.injectVariables(it) }
                    ?: throw IllegalStateException("No password")
                Firebase.auth.signInWithEmailAndPassword(email, password)
            }
        }
    }
}
