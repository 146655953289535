package com.appcreator.client.contentful.richtext

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.widthIn
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.intl.Locale
import com.appcreator.blueprint.components.basic.ImageComponent
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.SizeValue
import com.appcreator.client.contentful.RichTextInfo
import com.appcreator.client.contentful.extractAsset
import com.appcreator.compose.components.basic.ImageComposable


@Composable
internal fun EmbeddedAsset(
    node: RichNode.EmbeddedAssetBlock,
    info: RichTextInfo,
) {

    val details = node.data.target.sys.id.extractAsset(root = info.root).toMap()
    if (details.containsKey(ContentfulLoaderSpec.Keys.fileUrl)) {

        val width = details[ContentfulLoaderSpec.Keys.width]?.toString()?.toFloatOrNull()
        val height = details[ContentfulLoaderSpec.Keys.height]?.toString()?.toFloatOrNull()

        val modifier = if(width != null && height != null) {
            Modifier
                .fillMaxWidth()
                .aspectRatio(width / height)
        } else Modifier.fillMaxWidth()


        val imageComponent = ImageComponent(
            _nodeId = null,
            _nodeLabel = null,
            _nodeRelativeId = null,
            imageResource = null,
            source = ImageComponent.Source.URL,
            scale = ImageComponent.Scale.FillWidth,
            url = LocalizableString(localizationEnabled = false, values = mapOf(Locale.current.toLanguageTag() to details[ContentfulLoaderSpec.Keys.fileUrl] as String)),
            height = null,
            width = SizeValue.Percent(100),
            loading = null,
            fallback = null
        )
        val boxModifier = if(width != null) {
            Modifier.widthIn(max = with(LocalDensity.current) { width.toDp() })
        } else Modifier
        Box(boxModifier) {
            ImageComposable(modifier, imageComponent)
        }
    }
}



@Composable
internal fun EmbeddedEntry(
    node: RichNode.EmbeddedEntryBlock,
    info: RichTextInfo,
) {
//    includes?.entry?.find { it.sys.id == node.data.target.sys.id }?.let { details ->
//        details.sys.contentType?.sys?.id?.let { contentType ->
//            customRenderers.renderers[contentType]?.Render(details.fields)
//        }
//    }
}