package com.appcreator.client.firebase.firestore

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.firestore.CollectionReference
import dev.gitlive.firebase.firestore.DocumentReference
import dev.gitlive.firebase.firestore.firestore


sealed class PathReference {
    data class Collection(
        val ref: CollectionReference,
        val collectionName: String
    ): PathReference()
    data class Document(val ref: DocumentReference): PathReference()
}

internal fun String.pathToReferences(): PathReference {
    val pathParts = trimStart('/').trimEnd('/').split("/")
    var collection: CollectionReference? = Firebase.firestore.collection(pathParts.first())
    var documentRef: DocumentReference? = null

    pathParts.drop(1).forEachIndexed { index, part ->
        when (index % 2) {
            1 -> {
                collection = documentRef?.collection(part)
                documentRef = null
            }

            else -> {
                documentRef = collection?.document(part)
                collection = null
            }
        }
    }
    collection?.let {
        return PathReference.Collection(it, pathParts.last())
    }
    documentRef?.let {
        return PathReference.Document(it)
    }
    throw IllegalStateException("Invalid path")
}