package com.appcreator.blueprint.components.forms

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@SerialName("TimePicker")
@Serializable
@ComponentClass(
    group = "Forms",
    title = "Time Picker",
    availableFrom = "0.0.1"
)
data class TimePickerComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Hour Value",
        availableFrom = "0.0.1",
        settableDataOnly = true,
    )
    val hourValue: DataPath?,

    @InputProperty(
        title = "Minute Value",
        availableFrom = "0.0.1",
        settableDataOnly = true,
    )
    val minuteValue: DataPath?

): Component
