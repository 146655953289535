package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class TypedDataValue(
    val key: String,
    val value: String,
    val type: Type
) {

    enum class Type {
        String,
        Number,
        Boolean,
        Timestamp,
        StringArray
    }

}
