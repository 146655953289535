package com.appcreator.compose.extensions

import kotlinx.datetime.Instant
import kotlinx.datetime.UtcOffset
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.DateTimeComponents.Companion.Format
import kotlinx.datetime.format.DateTimeFormat
import kotlinx.datetime.format.alternativeParsing
import kotlinx.datetime.format.char
import kotlinx.datetime.format.optional

expect fun format(date: String, from: String, to: String): String

fun String.parseToInstant(): Instant = Instant.parse(this, ISO_DATE_TIME_OFFSET)

private val ISO_DATE_TIME_OFFSET: DateTimeFormat<DateTimeComponents> = Format {
    year()
    char('-')
    monthNumber()
    char('-')
    dayOfMonth()
    alternativeParsing({
        char('t')
    }) {
        char('T')
    }
    hour()
    char(':')
    minute()
    optional {
        char(':')
        second()
    }
    optional {
        char('.')
        secondFraction(1, 9)
    }
    alternativeParsing({
        offsetHours()
    }) {
        offset(UtcOffset.Formats.ISO)
    }
}