package com.appcreator.compose.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.Component

fun interface ComponentContentWrapper {
    @Composable
    fun Wrap(modifier: Modifier, component: Component, content: @Composable (Modifier) -> Unit)
}

val LocalComponentModifierProvider = staticCompositionLocalOf {
    ComponentContentWrapper { modifier, _, content -> content(modifier) }
}
