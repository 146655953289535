package com.appcreator.blueprint.actions.data

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("SetTitle")
@ActionClass(
    group = "Data",
    title = "Set Title",
    availableFrom = "0.0.1"
)
class SetTitleAction(
    @InputProperty(
        title = "Title",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val title: String?,
): Action
