package com.appcreator.blueprint.revenuecat

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("RevenuecatHasEntitlementCondition")
@ComponentClass(
    group = RevenuecatConsts.group,
    title = "Paywall",
    availableFrom = "0.0.1",
    isExtraPlugin = true,
)
data class RevenuecatPaywallComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        section = "Actions",
        title = "On Purchase",
        availableFrom = "0.0.1"
    )
    val onPurchase: Action?,

    @InputProperty(
        section = "Actions",
        title = "On Close",
        availableFrom = "0.0.1"
    )
    val onClose: Action?,

): Component