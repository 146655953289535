package com.appcreator.compose.conversions

import com.appcreator.blueprint.conversions.NowConversion
import com.appcreator.blueprint.core.EnvStore
import kotlinx.datetime.Clock
import kotlinx.serialization.json.JsonElement

class NowConvertor(
    val conversion: NowConversion
): Convertor {
    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String {
        return Clock.System.now().toString()
    }

}
