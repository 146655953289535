package com.appcreator.client.firebase.storage

import com.appcreator.blueprint.firebase.storage.FirebaseStorageUploadImageAction
import com.appcreator.compose.di.Container


object FirebaseStorageInitializer {
    fun initialize() {
        Container.registerAction(FirebaseStorageUploadImageAction::class) {
            UploadImagePerformer(it)
        }
    }
}

