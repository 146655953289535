package com.appcreator.compose.conditions.data

import com.appcreator.blueprint.conditions.data.NumberCompareCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator

class NumberCompareEvaluator(private val condition: NumberCompareCondition): Evaluator {
    override fun evaluateOnce(envStore: EnvStore): Boolean {
        val lhs = envStore.injectVariables(condition.lhs).toDoubleOrNull()?: return false
        val rhs = envStore.injectVariables(condition.rhs).toDoubleOrNull()?: return false
        return when (condition.comparison) {
            NumberCompareCondition.Comparison.Equals -> lhs == rhs
            NumberCompareCondition.Comparison.GreaterThan -> lhs > rhs
            NumberCompareCondition.Comparison.LessThan -> lhs < rhs
        }
    }

}