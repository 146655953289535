package com.appcreator.compose

import androidx.compose.runtime.Composable
import kotlinx.browser.window


@Composable
actual fun platformTitleUpdater(): (String) -> Unit = {
    if(it.isNotEmpty()) {
        window.document.title = it
    }
}