package com.appcreator.compose

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Scaffold
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.rememberTopAppBarState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.nestedscroll.nestedScroll
import com.appcreator.blueprint.components.Screen
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.navigation.NavigationBar

@Composable
actual fun ScreenComposableContent(
    modifier: Modifier,
    screen: Screen,
    navigator: Navigator,
    includeToolbar: Boolean
) {
    if (includeToolbar && !LocalModal.current) {
        val scrollBehavior = if (screen.navigationStyle == Screen.NavigationStyle.Large) {
            TopAppBarDefaults.enterAlwaysScrollBehavior(rememberTopAppBarState())
        } else null
        Scaffold(
            modifier = scrollBehavior?.let {
                modifier.nestedScroll(it.nestedScrollConnection)
            } ?: modifier,
            topBar = { NavigationBar(navigator, screen, scrollBehavior) }
        ) { padding ->
            ScreenComposableContentInner(Modifier.padding(padding), screen)
        }
    } else {
        ScreenComposableContentInner(modifier, screen)
    }

}

@Composable
private fun ScreenComposableContentInner(modifier: Modifier, screen: Screen) {
        BoxWithConstraints(modifier) {
            CompositionLocalProvider(LocalAbsoluteSize provides AbsoluteSize(this)) {
                screen.content?.let {
                    ComponentComposable(Modifier, it)
                }
            }
        }
}