package com.appcreator.compose.extensions

import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import com.appcreator.blueprint.core.properties.ColorValue
import com.appcreator.compose.LocalEnvStore

@Composable
fun ColorValue.composeColor(): Color {
    val envStore = LocalEnvStore.current
    return try {
        envStore.injectVariables(baseHex).toColor()
    } catch (ex: Exception) {
        MaterialTheme.colorScheme.primary
    }
}
// TODO theme values
//    if(isSystemInDarkTheme()) {
//        (themeValues["dark"]?: baseHex).toColor()
//    } else {
//        (themeValues["light"]?: baseHex).toColor()
//    }

fun String.toColor(): Color {
    val color = if (length == 7) {
        "ff" + removePrefix("#").lowercase()
    } else {
        removePrefix("#").lowercase()
    }
    return Color(color.toLong(16))
}
