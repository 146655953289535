package com.appcreator.client.firebase.remoteconfig

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigCondition
import com.appcreator.compose.conditions.Evaluator
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.remoteconfig.remoteConfig

class RemoteConfigEvaluator(private val condition: FirebaseRemoteConfigCondition): Evaluator {

    override fun evaluateOnce(envStore: EnvStore): Boolean {
        return condition.keyName?.let {
            val name = envStore.injectVariables(it)
            Firebase.remoteConfig.getValue(name).asBoolean()
        }?: false
    }

}