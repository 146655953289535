package com.appcreator.blueprint.conditions.logic

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Not")
@ConditionClass(
    group = "Logic",
    title = "Not",
    availableFrom = "0.0.1",
    accessible = false
)
data class NotCondition(
    @InputProperty(
        title = "Enabled",
        availableFrom = "0.0.1"
    )
    val enabled: Boolean?,
    @InputProperty(
        title = "Conditions",
        availableFrom = "0.0.1"
    )
    val notCondition: Condition?
): Condition
