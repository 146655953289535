package com.appcreator.blueprint.firebase.remoteconfig

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseRemoteConfigCondition")
@ConditionClass(
    group = FirebaseRemoteConfigConsts.group,
    title = "Firebase Remote Config",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseRemoteConfigCondition(
    @InputProperty(
        title = "Key Name",
        availableFrom = "0.0.1"
    )
    val keyName: String?
): Condition
