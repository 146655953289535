package com.appcreator.blueprint.conditions.data

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ContainsPart")
@ConditionClass(
    group = "Data",
    title = "Contains",
    availableFrom = "0.0.1"
)
data class ContainsCondition(

    @InputProperty(
        title = "Data",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val data: String,
    @InputProperty(
        title = "Contains",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val value: String

): Condition
