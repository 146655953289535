package com.appcreator.compose.actions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.AlertAction
import com.appcreator.blueprint.core.EnvStore

class AlertPerformer(action: AlertAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
//                if(evaluator?.evaluateAsync(envStore) == true) {
//                    deferred?.perform(envStore)
//                }
            }
        }
    }
}