package com.appcreator.compose

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.appcreator.blueprint.components.CustomClientComponent
import com.appcreator.blueprint.components.CustomComponent
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.SizingView

@Composable
fun CustomComponentComposable(modifier: Modifier, customComponent: CustomComponent) {
    when(customComponent.previewStyle) {
        CustomComponent.PreviewStyle.Component -> customComponent.previewComponent?.let {
            ComponentComposable(modifier, it)
        }
        CustomComponent.PreviewStyle.Size -> SizingView(
            customComponent.width,
            customComponent.height
        ) { mod, _, _ ->
            Box(modifier.then(mod).fillMaxSize().background(Color.LightGray), contentAlignment = Alignment.Center) {
                Text(customComponent.userDefinedComponent?.name ?: "")
            }
        }

        else -> {}
    }
}

@Composable
fun CustomClientComponentComponent(modifier: Modifier, customComponent: CustomClientComponent) {
    customComponent.content?.let {
        ComponentComposable(modifier, it)
    }?: run {
        when (customComponent.previewStyle) {
            CustomClientComponent.PreviewStyle.Component -> customComponent.previewComponent?.let {
                ComponentComposable(modifier, it)
            }

            CustomClientComponent.PreviewStyle.Size -> SizingView(
                customComponent.width,
                customComponent.height
            ) { mod, _, _ ->
                Box(
                    modifier.then(mod).fillMaxSize().background(Color.LightGray),
                    contentAlignment = Alignment.Center
                ) {
                    Text(customComponent.userDefinedComponent?.name ?: "")
                }
            }

            else -> {}
        }
    }
}