package com.appcreator.compose.loaders

import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.dataspecs.ConversionDataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec

fun LoaderSpec.createPreviewData(): Map<String, Any> =
    dataShape?.options?.toPreviewMap() ?: emptyMap()


private fun List<DataSpec>.toPreviewMap(): Map<String, Any> {
   return associate {
       it.key to when(it) {
            is ValueDataSpec ->it.value?: ""
            is ListDataSpec -> listOf(it.items.toPreviewMap())
            is ConversionDataSpec -> it.previewValue?: ""
            else -> ""
        }
    }
}