package com.appcreator.compose.components.forms

import androidx.compose.material3.TimePicker
import androidx.compose.material3.rememberTimePickerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.forms.TimePickerComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInputEnvStore

@Composable
fun TimePickerComposable(modifier: Modifier, component: TimePickerComponent) {

    val envStore = LocalEnvStore.current
    val inputEnvStore = LocalInputEnvStore.current

    val min = envStore.injectVariables("{${component.minuteValue?.value}}")
    val hour = envStore.injectVariables("{${component.hourValue?.value}}")

    val state = rememberTimePickerState(
        initialMinute = min.toIntOrNull()?: 0,
        initialHour = hour.toIntOrNull()?: 0,
    )

    TimePicker(state = state)

    LaunchedEffect(state.hour) {
        component.hourValue?.let {
            inputEnvStore.set(it.value, state.hour.toString())
        }
    }
    LaunchedEffect(state.minute) {
        component.minuteValue?.let {
            inputEnvStore.set(it.value, state.minute.toString())
        }
    }

    LaunchedEffect(min) {
        min.toIntOrNull()?.let {
            state.minute = it
        }
    }
    LaunchedEffect(hour) {
        hour.toIntOrNull()?.let {
            state.hour = it
        }
    }
}

