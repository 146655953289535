package com.appcreator.client.firebase.auth

import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.firebase.auth.FirebaseAuthConsts
import com.appcreator.blueprint.firebase.auth.FirebaseAuthDeleteUserAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoggedInCondition
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoginAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLogoutAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthResetPasswordAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSetDisplayNameAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpAction
import com.appcreator.compose.di.Container
import com.appcreator.compose.events.LoginEvent
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch

object FirebaseAuthInitializer {

    fun initialize() {
        Container.registerCondition(FirebaseAuthLoggedInCondition::class) {
            LoggedInEvaluator(it)
        }

        Container.registerAction(FirebaseAuthDeleteUserAction::class) {
            DeleteUserPerformer(it)
        }
        Container.registerAction(FirebaseAuthLoginAction::class) {
            LoginPerformer(it)
        }
        Container.registerAction(FirebaseAuthLogoutAction::class) {
            LogoutPerformer(it)
        }
        Container.registerAction(FirebaseAuthResetPasswordAction::class) {
            ResetPasswordPerformer(it)
        }
        Container.registerAction(FirebaseAuthSignUpAction::class) {
            SignUpPerformer(it)
        }
        Container.registerAction(FirebaseAuthSetDisplayNameAction::class) {
            SetDisplayNamePerformer(it)
        }

        val userId = mutableStateOf(Firebase.auth.currentUser?.uid ?: "")
        Container.globalKeys[FirebaseAuthConsts.uid] = userId

        val userDisplayName = mutableStateOf(Firebase.auth.currentUser?.displayName ?: "")
        Container.globalKeys[FirebaseAuthConsts.userDisplayName] = userDisplayName

        val userAuthToken = mutableStateOf("")
        Container.globalKeys[FirebaseAuthConsts.userToken] = userAuthToken

        GlobalScope.launch {
            Firebase.auth.authStateChanged.collect {
                userId.value = it?.uid?: ""
                userDisplayName.value = it?.displayName?: ""
                Container.sendEvent(LoginEvent(it?.uid, it?.email, it?.displayName))
            }
        }
        GlobalScope.launch {
            Firebase.auth.idTokenChanged.collect {
                userAuthToken.value = it?.getIdToken(false) ?: ""
                println("Token ${userAuthToken.value}")
            }
        }
    }
}
