package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class  ColorValue(
    val id: String,
    val name: String,
    val baseHex: String,
    val themeValues: Map<String, String>,
    val userCreated: Boolean?
)

@Serializable
data class ColorReference(
    val id: String?,
    val custom: ColorValue?
)
