package com.appcreator.dto

import com.appcreator.blueprint.components.Screen
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ScreenResponse(
    val screen: Screen,
    val updatedAt: Instant
)
