package com.appcreator.compose.actions.data

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.data.DataConversionAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.convertor
import com.appcreator.compose.di.performer

class DataConversionPerformer(private val action: DataConversionAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val deferred = action.subAction?.let { Container.performer(it)?.deferred() }
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.conversion?.let { conversion ->
                    action.outputPath?.let { output ->
                        Container.convertor(conversion)?.convert(Container.jsConfig!!, envStore, null)?.let { converted ->
                            val updatedEnv = EnvStore.create(envStore, env = mapOf(output to converted))
                            deferred?.perform(updatedEnv)
                        }
                    }
                }
            }
        }
    }
}
