package com.appcreator.dto.configurations

import kotlinx.serialization.Serializable

@Serializable
data class ContentfulConfiguration(
    val space: String,
    val environment: String,
    val accessToken: String
)
