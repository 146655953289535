package com.appcreator.blueprint.components.data

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("DateParts")
@ComponentClass(
    group = "Data",
    title = "Date Elements",
    availableFrom = "0.0.1",
    producesDataFields =
        """"date-parts.year",
        "date-parts.month-number",
        "date-parts.month-name",
        "date-parts.month-name-short",
        "date-parts.day-number",
        "date-parts.day-name",
        "date-parts.day-name-short",
        "date-parts.hour-24",
        "date-parts.minute",
        "date-parts.second""""
)
data class DatePartsComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Date",
        description = "Value to be split into its date parts",
        availableFrom = "0.0.1"
    )
    val date: String?,

    @InputProperty(
        title = "Content",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

): Component

