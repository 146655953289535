package com.appcreator.compose.conditions.data

import com.appcreator.blueprint.conditions.data.EqualsCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator

class EqualsEvaluator(private val condition: EqualsCondition): Evaluator {

    override fun evaluateOnce(envStore: EnvStore): Boolean {
        val lhs = envStore.injectVariables(condition.data)
        val rhs = envStore.injectVariables(condition.value)
        return lhs == rhs
    }

}