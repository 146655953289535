package com.appcreator.blueprint.components.data

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Countdown")
@ComponentClass(
    group = "Data",
    title = "Countdown Timer",
    availableFrom = "0.0.1",
    producesDataFields =
        """"countdown.years",
        "countdown.months",
        "countdown.days",
        "countdown.hours",
        "countdown.minutes",
        "countdown.seconds""""

)
data class CountdownComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Until",
        description = "Date to countdown until",
        availableFrom = "0.0.1"
    )
    val until: String?,

    @InputProperty(
        title = "Content",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

): Component

