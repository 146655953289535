package com.appcreator.blueprint.components.forms

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@SerialName("DatePickerModal")
@Serializable
@ComponentClass(
    group = "Forms",
    title = "Date Picker",
    availableFrom = "0.0.1"
)
data class DatePickerModalComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Anchor",
        description = "Component to be used as the button to open the picker",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val button: Component?,

    @InputProperty(
        title = "Earliest Allowed",
        availableFrom = "0.0.1"
    )
    val earliestAllowed: String?,

    @InputProperty(
        title = "Latest Allowed",
        availableFrom = "0.0.1"
    )
    val latestAllowed: String?,

    @InputProperty(
        title = "Date Value",
        availableFrom = "0.0.1",
        settableDataOnly = true,
    )
    val date: DataPath?

): Component
