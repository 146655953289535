package com.appcreator.compose.actions.navigation

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.actions.navigation.OpenScreenAction
import com.appcreator.blueprint.components.modal.SheetComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalModal
import com.appcreator.compose.LocalNavigator
import com.appcreator.compose.LocalScreenTitle
import com.appcreator.compose.ScreenComposable
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.components.modal.DialogComponentContent
import com.appcreator.compose.components.modal.SheetComponentContent
import kotlinx.coroutines.launch

class OpenScreenPerformer(private val action: OpenScreenAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val sheet = remember { mutableStateOf<Destination?>(null) }
        SheetNavigation(sheet)

        val dialog = remember { mutableStateOf<Destination?>(null) }
        DialogNavigation(sheet)

        val navigator = LocalNavigator.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.link?.let {
                    when(action.openType) {
                        OpenScreenAction.OpenType.Sheet -> sheet.value = navigator?.findDestination(it, envStore)
                        OpenScreenAction.OpenType.Dialog -> dialog.value = navigator?.findDestination(it, envStore)
                        OpenScreenAction.OpenType.Tab -> navigator?.navigate(it, envStore) {
                            popUpToRoot = true
                            singleTop = true
                        }
                        OpenScreenAction.OpenType.StandardReplace -> {
                            navigator?.navigate(it, envStore) {
                                popUpToRoot = true
                            }
                        }
                        else -> navigator?.navigate(it, envStore)
                    }
                }
            }
        }
    }

}

@Composable
internal fun SheetNavigation(destination: MutableState<Destination?>) {

    val navigator = LocalNavigator.current
    destination.value?.let {
        val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
        SheetComponentContent(
            sheetState = sheetState,
            color = MaterialTheme.colorScheme.background,
            size = SheetComponent.Size.Calculated,
            dismiss = {
                destination.value = null
            }
        ) {
            CompositionLocalProvider(
                LocalModal provides true,
                LocalScreenTitle provides remember { mutableStateOf("") }
            ) {
                ScreenComposable(it)
            }
        }

        val scope = rememberCoroutineScope()
        DisposableEffect(Unit) {
            navigator?.registerModal {
                scope.launch {
                    sheetState.hide()
                    destination.value = null
                }
            }
            onDispose {
                navigator?.unregisterModal()
            }
        }
    }
}

@Composable
internal fun DialogNavigation(destination: MutableState<Destination?>) {

    val navigator = LocalNavigator.current
    destination.value?.let {
        var showing by remember { mutableStateOf(false) }
        if(showing) {
            DialogComponentContent(
                color = MaterialTheme.colorScheme.surface,
                dismiss = {
                    destination.value = null
                }
            ) {
                CompositionLocalProvider(
                    LocalModal provides true,
                    LocalScreenTitle provides remember { mutableStateOf("") }
                ) {
                    ScreenComposable(it)
                }
            }
        }

        val scope = rememberCoroutineScope()
        DisposableEffect(Unit) {
            navigator?.registerModal {
                scope.launch {
                    showing = false
                    destination.value = null
                }
            }
            onDispose {
                navigator?.unregisterModal()
            }
        }
    }
}
