package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("UppercaseDataConversion")
@ConversionClass(
    title = "Uppercase",
    availableFrom = "0.0.1"
)
data class UppercaseConversion(
    @InputProperty(
        title = "Data Path",
        availableFrom = "0.0.1"
    )
    val dataPath: DataPath?,
): Conversion
