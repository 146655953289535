package com.appcreator.compose

class TriggerBus {

    private val listeners = mutableMapOf<String, () -> Unit>()

    fun addListener(id: String, listener: () -> Unit) {
        listeners[id] = listener
    }

    fun removeListener(id: String) {
        listeners.remove(id)
    }

    fun trigger(id: String) {
        listeners[id]?.invoke()
    }

}