package com.appcreator.client.firebase.firestore

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.TypedDataValue
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreConsts.stringArrayItemName
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataAction
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.extensions.parseToInstant
import dev.gitlive.firebase.firestore.Timestamp

class SetDataPerformer(private val action: FirebaseFirestoreSetDataAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.path?.let { path ->

                    val injectedPath = envStore.injectVariables(path)
                    val pathRef = when(val reference = injectedPath.pathToReferences()) {
                        is PathReference.Collection -> {
                            reference.ref.document(getRandomString())
                        }
                        is PathReference.Document -> {
                            reference.ref
                        }
                    }

                    action.data
                        ?.mapNotNull {
                            val typed: Any? = when(it.type) {
                                TypedDataValue.Type.String -> envStore.injectVariables(it.value)
                                TypedDataValue.Type.Number -> envStore.injectVariables(it.value).toIntOrNull()
                                TypedDataValue.Type.Boolean -> envStore.injectVariables(it.value).toBooleanStrictOrNull()
                                TypedDataValue.Type.Timestamp -> {
                                    val time = envStore.injectVariables(it.value).parseToInstant()
                                    Timestamp(time.epochSeconds, 0)
                                }
                                TypedDataValue.Type.StringArray -> {
                                    when (val raw = envStore.get(it.value)) {
                                        is List<*> -> {
                                            raw.mapNotNull { item ->
                                                (item as? Map<String, Any>)?.let { map ->
                                                    map[stringArrayItemName] as? String
                                                }
                                            }
                                        }
                                        else -> envStore.injectVariables(it.value).split(",")
                                    }
                                }
                            }
                            typed?.let { value ->
                                it.key to value
                            }
                        }?.toMap()?.let {
                            pathRef.set(it, merge = true)
                        }
                }
            }
        }
    }
}

private fun getRandomString(length: Int = 12) : String {
    val allowedChars = ('A'..'Z') + ('a'..'z')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}