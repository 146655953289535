package com.appcreator.client.firebase.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.auth.FirebaseAuthConsts
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSetDisplayNameAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpAction
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.di.Container
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth

class SetDisplayNamePerformer(val action: FirebaseAuthSetDisplayNameAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                val displayName = action.displayName?.let { envStore.injectVariables(it) } ?: throw IllegalStateException("No Display Name")
                Firebase.auth.currentUser?.updateProfile(displayName = displayName)
                (Container.globalKeys[FirebaseAuthConsts.userDisplayName] as? MutableState<String>)?.let {
                    it.value = displayName
                }
            }
        }
    }
}