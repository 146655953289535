package com.appcreator.compose.components.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.data.CountdownComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.EnvComposable
import kotlinx.coroutines.delay
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.toDateTimePeriod

@Composable
fun CountdownComposable(modifier: Modifier, component: CountdownComponent) {
    val envStore = LocalEnvStore.current

    component.until?.let { until ->
        component.content?.let {

            val dateString = envStore.injectVariables(until)
            var properties by remember(dateString) { mutableStateOf(createProperties(dateString)) }

            LaunchedEffect(dateString) {
                while (true) {
                    delay(1000)
                    properties = createProperties(dateString)
                }
            }

            EnvComposable(properties) {
                ComponentComposable(modifier, it)
            }
        }
    }
}

private fun createProperties(dateString: String): Map<String, String> {
    val date = Instant.parse(dateString)
    val now = Clock.System.now()
    val duration = date - now
    val timePeriod = duration.toDateTimePeriod()
    return mapOf(
        "countdown.years" to timePeriod.years.coerceAtLeast(0).toString(),
        "countdown.months" to timePeriod.months.coerceAtLeast(0).toString(),
        "countdown.days" to timePeriod.days.coerceAtLeast(0).toString(),
        "countdown.hours" to timePeriod.hours.coerceAtLeast(0).toString().padStart(2, '0'),
        "countdown.minutes" to timePeriod.minutes.coerceAtLeast(0).toString().padStart(2, '0'),
        "countdown.seconds" to timePeriod.seconds.coerceAtLeast(0).toString().padStart(2, '0'),
    )
}
