package com.appcreator.client.contentful

import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.contentful.ContentfulRichTextComponent
import com.appcreator.compose.di.Container
import io.ktor.client.HttpClient

object ContentfulInitializer {
    fun initialize(
        client: HttpClient,
        space: String,
        environment: String,
        accessToken: String
    ) {
        Container.registerLoader(ContentfulLoaderSpec::class) {
            ContentfulLoader(client, space, environment, accessToken, it)
        }
        Container.registerComponent(ContentfulRichTextComponent::class) { mod, component ->
            RichTextComposable(mod, component)
        }
    }
}

