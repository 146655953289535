package com.appcreator.compose.api

import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.dataspecs.ConversionDataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.loaderspec.JsonLoaderSpec
import com.appcreator.compose.conversions.jsonValue
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.convertor
import com.appcreator.compose.extensions.JSEvaluationConfig
import io.ktor.client.HttpClient
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonPrimitive

class JsonFetcher(
    private val client: HttpClient,
    private val jsEvaluationConfig: JSEvaluationConfig
) {

    @Throws(Throwable::class)
    suspend fun fetchJsonIntoEnv(envStore: EnvStore, spec: JsonLoaderSpec, mapping: Map<String, String>): Map<String, Any> {
        val defaults = spec.parameterSettings?.map { (key, value) -> key to value.default }?.toMap()?: emptyMap()
        val injectUrl = envStore.injectUrlSpec(spec.urlSpec, mapping, defaults)

        val json = client.requestJson(injectUrl)
        val properties = mutableMapOf<String, Any>()
        spec.dataShape?.let {
            val inputParameters = spec.parameters.associateWith { key -> envStore.injectValueWithMapping("{$key}", mapping, defaults) }
            extract(
                parameters = inputParameters,
                specs = it.options,
                from = json,
                into = properties
            )
        }
        return properties
    }

    private suspend fun extract(
        parameters: Map<String, String>,
        specs: List<DataSpec>,
        from: JsonElement,
        into: MutableMap<String, Any>
    ) {
        specs.forEach { dataItem ->
            try {
                val value: Any? = when(dataItem) {
                    is ConversionDataSpec -> dataItem.conversion?.let { Container.convertor(it)?.convert(jsEvaluationConfig, EnvStore.Companion.create("GB", parameters + into), from) }
                    is ValueDataSpec -> jsonValue(from, dataItem.key)?.jsonPrimitive?.content
                    is ListDataSpec -> jsonValue(from, dataItem.key)?.jsonArray?.map { jsonListItem ->
                        val properties = mutableMapOf<String, Any>()
                        extract(parameters = parameters, specs = dataItem.items, from = jsonListItem, into = properties)
                        properties
                    }
                    else -> null
                }
                value?.let { into[dataItem.key] = it }
            } catch (ex: Exception) {
//                ex.printStackTrace()
                println("Parse error for path ${dataItem.key}")
            }
        }
    }

}