package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class SideValues(
    val locked: Boolean,
    val start: Int?,
    val top: Int?,
    val end: Int?,
    val bottom: Int?
)