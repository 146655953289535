package com.appcreator.client.firebase.firestore

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreDeleteDataAction
import com.appcreator.compose.actions.Performer

class DeleteDataPerformer(private val action: FirebaseFirestoreDeleteDataAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.path?.let { path ->
                    val injectedPath = envStore.injectVariables(path)
                    val pathRef = when (val reference = injectedPath.pathToReferences()) {
                        is PathReference.Collection -> {
                            throw IllegalStateException("Can not delete collection")
                        }

                        is PathReference.Document -> { reference.ref }
                    }
                    pathRef.delete()
                }
            }
        }
    }
}