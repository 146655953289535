package com.appcreator.compose.components.modal

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.appcreator.blueprint.components.modal.DialogComponent
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize
import com.appcreator.compose.LocalAnchorAction
import com.appcreator.compose.LocalDrawOnRoot
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.LocalNavigator
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.LocalTriggerBus
import com.appcreator.compose.SnackbarAlertContainer
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.composeColor
import kotlinx.coroutines.launch

@Composable
fun DialogComposable(modifier: Modifier, component: DialogComponent) {

    var showSheet by remember { mutableStateOf(false) }
    val inPreview = LocalInPreview.current

    component.button?.let {
        CompositionLocalProvider(LocalAnchorAction provides {
            if(!inPreview) {
                showSheet = true
            }
        }) {
            ComponentComposable(modifier = modifier, component = it)
        }
    }

    if (inPreview && component.showInPreview == true) {
        PreviewDialog(modifier, component)
    } else {

        component.triggerId?.let {
            val triggerBus = LocalTriggerBus.current
            DisposableEffect(it) {
                triggerBus.addListener(it) { showSheet = true }
                onDispose { triggerBus.removeListener(it) }
            }
        }

        if (showSheet || (inPreview && component.showInPreview == true)) {
            val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
            val color = LocalTheme.current?.color(component.sheetColor)?.composeColor()
                ?: MaterialTheme.colorScheme.surface
            val scope = rememberCoroutineScope()
            DialogComponentContent(
                color = color,
                dismiss = {
                    showSheet = false
                }
            ) {
                component.content?.let {
                    ComponentComposable(Modifier, it)
                }
            }
            val navigator = LocalNavigator.current
            DisposableEffect(Unit) {
                navigator?.registerModal {
                    scope.launch {
                        sheetState.hide()
                        showSheet = false
                    }
                }
                onDispose {
                    navigator?.unregisterModal()
                }
            }
        }
    }
}

@Composable
internal fun DialogComponentContent(
    color: Color,
    dismiss: () -> Unit,
    content: @Composable () -> Unit
) {
    Dialog(
        onDismissRequest = dismiss,
        content = {
            BoxWithConstraints {
                SnackbarAlertContainer {
                    CompositionLocalProvider(
                        LocalAbsoluteSize provides AbsoluteSize(this)
                    ) {
                        Surface(
                            color =  color,
                            shape = RoundedCornerShape(8.dp)
                        ) {
                            content()
                        }
                    }
                }
            }
        }
    )
}

@Composable
internal fun PreviewDialog(
    modifier: Modifier,
    component: DialogComponent
) {
    val drawOnRoot = LocalDrawOnRoot.current
    DisposableEffect(component) {
        drawOnRoot.value = {
            Box(Modifier.padding(16.dp).fillMaxSize(), contentAlignment = Alignment.Center) {
                val color = LocalTheme.current?.color(component.sheetColor)?.composeColor()
                    ?: MaterialTheme.colorScheme.surface
                Surface(
                    modifier.align(Alignment.Center),
                    color =  color,
                    shape = RoundedCornerShape(8.dp)
                ) {
                    component.content?.let {
                        ComponentComposable(Modifier, it)
                    }
                }
            }
        }
        onDispose {
            drawOnRoot.value = null
        }
    }
}