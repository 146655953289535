package com.appcreator

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.compose.BlueprintComposable

@Composable
fun MyApp() {
    Column(Modifier.fillMaxSize()) {
        BlueprintComposable(
            drawableResource = { null },
            fontResources = emptyMap()
        )
    }
}
