package com.appcreator.compose.components.pager

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.PagerState
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ScrollableTabRow
import androidx.compose.material3.Tab
import androidx.compose.material3.TabPosition
import androidx.compose.material3.TabRow
import androidx.compose.material3.TabRowDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.layout
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.lerp
import com.appcreator.blueprint.components.data.DataRepeaterComponent
import com.appcreator.blueprint.components.pager.TabbedPageComponent
import com.appcreator.blueprint.components.pager.TabbedPagerComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.components.InputEnvComposable
import com.appcreator.compose.components.data.rememberRepeaterItems
import com.appcreator.compose.extensions.composeColor
import kotlinx.coroutines.launch

@Composable
fun TabbedPagerComposable(modifier: Modifier, component: TabbedPagerComponent) {

    InputEnvComposable(mapOf("pager-selected-page" to "0")) {

        val envStore = LocalEnvStore.current
        val theme = LocalTheme.current

        val flattened: List<Pair<TabbedPageComponent, EnvStore?>> =
            component.content.mapNotNull {
                when(it) {
                    is TabbedPageComponent -> listOf(it to null)
                    is DataRepeaterComponent -> (it.content as? TabbedPageComponent)?.let { content -> rememberRepeaterItems(envStore, it)?.map { env -> content to env } }
                    else -> null
                }
            }.flatten()

        val pagerState = rememberPagerState(initialPage = 0) { flattened.size }

//        val inputEnvStore = LocalInputEnvStore.current
//        LaunchedEffect(inputEnvStore.input["pager-selected-page"]) {
//            (inputEnvStore.input["pager-selected-page"] as? String)?.toIntOrNull()?.let {
//                pagerState.animateScrollToPage(it)
//            }
//        }
//        LaunchedEffect(pagerState.currentPage) {
//            inputEnvStore.set("pager-selected-page", pagerState.currentPage.toString())
//        }

        Column {
            val color = theme?.color(component.backgroundColor)?.composeColor() ?: Color.Unspecified
            val indicatorColor = theme?.color(component.indicatorColor)?.composeColor()
                ?: MaterialTheme.colorScheme.primary
            when (component.style) {
                TabbedPagerComponent.Style.Fixed -> TabRow(
                    selectedTabIndex = pagerState.currentPage,
                    containerColor = color,
                    indicator = {
                        TabRowDefaults.SecondaryIndicator(
                            Modifier.pagerTabIndicatorOffset(pagerState, it),
                            color = indicatorColor
                        )
                    },
                    divider = {}
                ) { TabContent(component, pagerState, indicatorColor, flattened) }

                TabbedPagerComponent.Style.Scrolling -> ScrollableTabRow(
                    selectedTabIndex = pagerState.currentPage,
                    edgePadding = 0.dp,
                    containerColor = color,
                    indicator = {
                        TabRowDefaults.SecondaryIndicator(
                            Modifier.pagerTabIndicatorOffset(pagerState, it),
                            color = indicatorColor
                        )
                    },
                    divider = {}
                ) { TabContent(component, pagerState, indicatorColor, flattened) }

                else -> {}
            }

            HorizontalPager(
                modifier = modifier.fillMaxSize(),
                state = pagerState,
                verticalAlignment = Alignment.Top
            ) {
                val (page, envStore) = flattened[it]

                envStore?.let { store ->
                    CompositionLocalProvider(LocalEnvStore provides store) {
                        TabbedPageComposableContent(Modifier, page)
                    }
                } ?: TabbedPageComposableContent(Modifier, page)
            }
        }
    }
}

@Composable
private fun TabContent(
    component: TabbedPagerComponent,
    pagerState: PagerState,
    color: Color,
    flattened: List<Pair<TabbedPageComponent, EnvStore?>>
) {
    val coroutineScope = rememberCoroutineScope()
    flattened.forEachIndexed { index, (page, envStore) ->
        val selected = index == pagerState.currentPage
        Tab(
            modifier = Modifier.height((component.tabHeight?: 50).dp),
            selected = selected,
            selectedContentColor = color,
            unselectedContentColor = MaterialTheme.colorScheme.onBackground,
            onClick = {
                println(index)
                coroutineScope.launch {
                    pagerState.animateScrollToPage(index)
                }
            }
        ) {
            envStore?.let { store ->
                CompositionLocalProvider(LocalEnvStore provides store) {
                    TabbedPageComposableTab(Modifier, selected, page)
                }
            } ?: TabbedPageComposableTab(Modifier, selected, page)
        }
    }
}

private fun Modifier.pagerTabIndicatorOffset(
    pagerState: PagerState,
    tabPositions: List<TabPosition>,
    pageIndexMapping: (Int) -> Int = { it },
): Modifier = layout { measurable, constraints ->
    if (tabPositions.isEmpty()) {
        // If there are no pages, nothing to show
        layout(constraints.maxWidth, 0) {}
    } else {
        val currentPage = minOf(tabPositions.lastIndex, pageIndexMapping(pagerState.currentPage))
        val currentTab = tabPositions[currentPage]
        val previousTab = tabPositions.getOrNull(currentPage - 1)
        val nextTab = tabPositions.getOrNull(currentPage + 1)
        val fraction = pagerState.currentPageOffsetFraction
        val indicatorWidth = if (fraction > 0 && nextTab != null) {
            lerp(currentTab.width, nextTab.width, fraction).roundToPx()
        } else if (fraction < 0 && previousTab != null) {
            lerp(currentTab.width, previousTab.width, -fraction).roundToPx()
        } else {
            currentTab.width.roundToPx()
        }
        val indicatorOffset = if (fraction > 0 && nextTab != null) {
            lerp(currentTab.left, nextTab.left, fraction).roundToPx()
        } else if (fraction < 0 && previousTab != null) {
            lerp(currentTab.left, previousTab.left, -fraction).roundToPx()
        } else {
            currentTab.left.roundToPx()
        }
        val placeable = measurable.measure(
            Constraints(
                minWidth = indicatorWidth,
                maxWidth = indicatorWidth,
                minHeight = 0,
                maxHeight = constraints.maxHeight
            )
        )
        layout(constraints.maxWidth, maxOf(placeable.height, constraints.minHeight)) {
            placeable.placeRelative(
                indicatorOffset,
                maxOf(constraints.minHeight - placeable.height, 0)
            )
        }
    }
}