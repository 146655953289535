package com.appcreator.compose.conditions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore

interface Evaluator {

    @Composable
    fun evaluate(envStore: EnvStore): Boolean? = evaluateOnce(envStore) == true
    suspend fun evaluateAsync(envStore: EnvStore): Boolean = evaluateOnce(envStore) == true
    fun evaluateOnce(envStore: EnvStore): Boolean?

}