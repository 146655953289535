package com.appcreator.blueprint.theme

import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.properties.ColorValue
import com.appcreator.blueprint.core.properties.Font
import com.appcreator.blueprint.core.properties.FontReference
import kotlinx.serialization.Serializable


@Serializable
data class Theme(
    val fonts: List<Font>,
    val colors: List<ColorValue>,
) {

    fun font(ref: FontReference?): Font? =
        ref?.id?.let { id -> fonts.find { id == it.id } }?: ref?.custom

    fun color(ref: ColorReference?): ColorValue? =
        ref?.id?.let { id -> colors.find { id == it.id } }?: ref?.custom

}


