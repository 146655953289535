package com.appcreator.blueprint.actions.data

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.LoaderConfig
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Api Request")
@ActionClass(
    group = "Data",
    title = "Send API Request",
    availableFrom = "0.0.1"
)
class ApiRequestAction(
    @InputProperty(
        title = "Loader",
        availableFrom = "0.0.1"
    )
    val config: LoaderConfig?,

    @InputProperty(
        title = "Completion Action",
        availableFrom = "0.0.1"
    )
    val subAction: Action?,

): Action
