package com.appcreator.compose.conversions

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.extensions.JSEvaluationConfig
import kotlinx.serialization.json.JsonElement

interface Convertor {
    fun convert(envStore: EnvStore, jsonElement: JsonElement?): String?
    suspend fun convert(config: JSEvaluationConfig, envStore: EnvStore, jsonElement: JsonElement?): String? =
        convert(envStore, jsonElement)

}