package com.appcreator.compose.components.data

import androidx.compose.material3.pulltorefresh.rememberPullToRefreshState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.components.data.LoadingComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.LocalScreenLastUpdated
import com.appcreator.compose.LocalTriggerBus
import com.appcreator.compose.PlatformPullToRefreshBox
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import com.appcreator.compose.loaders.createPreviewData
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant

@Composable
actual fun LoadingComposable(modifier: Modifier, component: LoadingComponent) {
    val envStore = LocalEnvStore.current
    val blueprint = LocalBlueprint.current
    var state: LoadingState by remember(component.config) { mutableStateOf(LoadingState.Loading(false)) }
    val deferred = component.action?.let { Container.performer(it)?.deferred() }

    val key = component.config?.parameters?.map { envStore.injectVariables(it.value, staticPass = true) }
    val inPreview = LocalInPreview.current

    val lastUpdated = LocalScreenLastUpdated.current
    val setPageLastUpdated: (Instant) -> Unit = {
        lastUpdated.at = it
    }

    val load: suspend () -> Unit = {
        load(inPreview, component, blueprint, envStore, deferred,
            onLoading = {
                state = LoadingState.Loading(true)
            },
            onLoad = {
                state = it
            },
            onError = {
                state = it
            },
            setPageLastUpdated
        )
    }

    component.reloadTrigger?.let {
        val bus = LocalTriggerBus.current
        val scope = rememberCoroutineScope()
        DisposableEffect(component.reloadTrigger) {
            bus.addListener(it) {
                scope.launch { load() }
            }
            onDispose {
                bus.removeListener(it)
            }
        }
    }

    LaunchedEffect(key, component.displayInPreview) {
        if(state !is LoadingState.Loaded) {
            load()
        }
    }

    val scope = rememberCoroutineScope()
    when(state) {
        is LoadingState.Error -> when(component.errorView) {
            LoadingComponent.ViewType.Nothing -> {}
            LoadingComponent.ViewType.Custom -> component.errorContent?.let { ComponentComposable(modifier, it) }
            else -> DefaultErrorComposable(modifier, (state as LoadingState.Error).ex) {
                scope.launch { load() }
            }
        }
        is LoadingState.Loaded -> {
            val loaded = (state as LoadingState.Loaded)
            val providers = listOfNotNull(loaded.providedValue, LocalEnvStore provides EnvStore.create(parent = envStore, loaded.env))
            CompositionLocalProvider(*providers.toTypedArray()) {
                component.content?.let { content ->
                    if (component.pullToRefresh == true) {
                        var refreshing by remember { mutableStateOf(false) }
                        val pullToRefresh = rememberPullToRefreshState()

                        val onRefresh: () -> Unit = {
                            refreshing = true
                            scope.launch {
                                load(inPreview, component, blueprint, envStore, deferred,
                                    onLoading = {

                                    },
                                    onLoad = {
                                        state = it
                                    },
                                    onError = {
                                        // TODO show error snackbar or something
                                    },
                                    setPageLastUpdated
                                )
                                refreshing = false
                            }
                        }

                        PlatformPullToRefreshBox(isRefreshing = refreshing, state = pullToRefresh, onRefresh = onRefresh) {
                            ComponentComposable(modifier, content)
                        }
                    } else {
                        ComponentComposable(modifier, content)
                    }
                }
            }
        }
        is LoadingState.Loading -> if((state as LoadingState.Loading).requiresIndicator) {
            when (component.loadingView) {
                LoadingComponent.ViewType.Nothing -> {}
                LoadingComponent.ViewType.Custom -> component.loadingContent?.let {
                    ComponentComposable(modifier, it)
                }
                else -> DefaultLoadingComposable(modifier)
            }
        }
    }
}

private suspend fun load(
    inPreview: Boolean,
    component: LoadingComponent,
    blueprint: Blueprint,
    envStore: EnvStore,
    deferred: Performer.Deferred?,
    onLoading: () -> Unit,
    onLoad: (LoadingState.Loaded) -> Unit,
    onError: (LoadingState.Error) -> Unit,
    setPageLastUpdated: (Instant) -> Unit
) {

    component.config?.let { config ->
        val loaderSpec = blueprint.loaderSpec(config.loaderSpec)?: run {
            println("----- No loader spec found for ${config.loaderSpec.id} -----")
            return@let
        }
        if (component.displayInPreview != null && inPreview) {
            when (component.displayInPreview) {
                LoadingComponent.DisplayInPreview.Loading -> { onLoading() }
                LoadingComponent.DisplayInPreview.Error -> { onError(LoadingState.Error(PreviewException())) }
                else -> {
                    onLoad(LoadingState.Loaded(null, loaderSpec.createPreviewData()))
                }
            }
        } else {
            Container.loaderRegistry[loaderSpec::class]?.let {
                coroutineScope {
                    val job = launch {
                        delay(600)
                        onLoading()
                    }

                    try {
                        val (data, updatedEnv) = it(loaderSpec).load(
                            envStore,
                            config.parameters,
                            setPageLastUpdated
                        )
                        deferred?.perform(EnvStore.create(parent = envStore, updatedEnv))

                        job.cancel()
                        onLoad(LoadingState.Loaded(data, updatedEnv))

                    } catch (ex: Exception) {
                        ex.printStackTrace()

                        job.cancel()
                        onError(LoadingState.Error(ex))
                    }
                }
            } ?: run {
                println("----- No loader spec registered for ${config.loaderSpec::class} -----")
            }
        }
    }
}

private class PreviewException: Exception()
