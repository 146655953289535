package com.appcreator.client.firebase.firestore

import androidx.compose.runtime.ProvidedValue
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreConsts.stringArrayItemName
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.compose.extensions.parseToInstant
import com.appcreator.compose.loaders.Loader
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.firestore.CollectionReference
import dev.gitlive.firebase.firestore.Direction
import dev.gitlive.firebase.firestore.DocumentSnapshot
import dev.gitlive.firebase.firestore.Filter
import dev.gitlive.firebase.firestore.FilterBuilder
import dev.gitlive.firebase.firestore.Query
import dev.gitlive.firebase.firestore.Timestamp
import dev.gitlive.firebase.firestore.firestore
import kotlinx.datetime.Instant

class FirestoreLoader(
    private val spec: FirebaseFirestoreLoaderSpec
): Loader {

    companion object {
        const val hasMore = "firebase-hasMore"
        const val startAfter = "firebase-startAfter"
    }

    override suspend fun load(
        envStore: EnvStore,
        mapping: Map<String, String>,
        setPageLastUpdated: ((Instant) -> Unit)?
    ): Pair<ProvidedValue<*>?, Map<String, Any>> {

        spec.dataPath ?: throw IllegalStateException("No path provided")

        val defaults = spec.parameterSettings?.map { (key, value) -> key to value.default }?.toMap() ?: emptyMap()
        val injectedPath = envStore.injectValueWithMapping(spec.dataPath!!, mapping, defaults)
        val startAfterKey = "$startAfter-$injectedPath"
        if (spec.queryScope == FirebaseFirestoreLoaderSpec.QueryScope.CollectionGroup) {
            return runQuery(
                startQuery = Firebase.firestore.collectionGroup(injectedPath),
                reference = null,
                startAfterKey = startAfterKey,
                startAfter = envStore.get(startAfterKey) as? DocumentSnapshot,
                name = injectedPath,
                envStore = envStore,
                mapping = mapping,
                defaults = defaults
            )
        }

        when (val reference = injectedPath.pathToReferences()) {
            is PathReference.Collection -> {
                return runQuery(
                    startQuery = null,
                    startAfterKey = startAfterKey,
                    startAfter = envStore.get(startAfterKey) as? DocumentSnapshot,
                    reference = reference.ref,
                    name = reference.collectionName,
                    envStore = envStore,
                    mapping = mapping,
                    defaults = defaults
                )
            }

            is PathReference.Document -> {
                val results = reference.ref.get().createDataMap(spec)
                return null to results
            }
        }
    }

    override fun canLoadMore(data: Map<String, Any>): Boolean = data[hasMore] == true


    private suspend fun runQuery(
        startQuery: Query?,
        startAfterKey: String,
        startAfter: DocumentSnapshot?,
        reference: CollectionReference?,
        name: String,
        envStore: EnvStore,
        mapping: Map<String, String>,
        defaults: Map<String, String>,
    ): Pair<ProvidedValue<*>?, Map<String, Any>> {

        val limit = spec.limit ?: 1000
        var query: Query = (startQuery?: reference)!!.limit(limit)

        if (spec.where?.isNotEmpty() == true) {
            val filter: FilterBuilder.() -> Filter? = {
                all(
                    *spec.where!!.mapNotNull {
                        when (it) {
                            is FirebaseFirestoreLoaderSpec.Where.Group -> throw IllegalStateException(
                                "Not yet supported"
                            )

                            is FirebaseFirestoreLoaderSpec.Where.In -> throw IllegalStateException("Not yet supported")
                            is FirebaseFirestoreLoaderSpec.Where.Value -> {
                                val injectedValue =
                                    envStore.injectValueWithMapping(it.value, mapping, defaults)
                                when (it.compareType) {
                                    FirebaseFirestoreLoaderSpec.WhereType.Equal -> it.field.equalTo(injectedValue)
                                    FirebaseFirestoreLoaderSpec.WhereType.NotEqual -> it.field.notEqualTo(injectedValue)
                                    FirebaseFirestoreLoaderSpec.WhereType.GreaterThan -> it.field.greaterThan(injectedValue)
                                    FirebaseFirestoreLoaderSpec.WhereType.GreaterThanOrEqual -> it.field.greaterThanOrEqualTo(injectedValue)
                                    FirebaseFirestoreLoaderSpec.WhereType.LessThan -> it.field.lessThan(injectedValue)
                                    FirebaseFirestoreLoaderSpec.WhereType.LessThanOrEqual -> it.field.lessThanOrEqualTo(injectedValue)
                                    FirebaseFirestoreLoaderSpec.WhereType.In -> if(injectedValue.isNotEmpty())
                                        it.field.inArray(injectedValue.split(",")) else null
                                    FirebaseFirestoreLoaderSpec.WhereType.ArrayContains -> {
                                        if (injectedValue.isNotEmpty())
                                            it.field.contains(injectedValue) else null
                                    }
                                    FirebaseFirestoreLoaderSpec.WhereType.BeforeTime -> {
                                        val time = injectedValue.parseToInstant()
                                        it.field.lessThan(Timestamp(time.epochSeconds, 0))
                                    }
                                    FirebaseFirestoreLoaderSpec.WhereType.AfterTime -> {
                                        val time = injectedValue.parseToInstant()
                                        it.field.greaterThan(Timestamp(time.epochSeconds, 0))
                                    }
                                }
                            }
                        }
                    }.toTypedArray()
                )
            }
            query = query.where(filter)
        }

        spec.orderBy?.forEach {
            val direction = when (it.direction) {
                FirebaseFirestoreLoaderSpec.OrderBy.Ascending -> Direction.ASCENDING
                FirebaseFirestoreLoaderSpec.OrderBy.Descending -> Direction.DESCENDING
            }
            query = query.orderBy(it.field, direction)
        }

        if (startAfter != null) {
            query = query.startAfter(startAfter)
        }

        val querySnapshot = query.get()
        val results = querySnapshot
            .documents.map { doc ->
                doc.createDataMap(spec)
            }

        val pagingData = querySnapshot.documents.lastOrNull()?.let {
            mapOf(
                hasMore to (querySnapshot.documents.size == limit),
                startAfterKey to it
            )
        }?: emptyMap()

        return null to mapOf(name to results).plus(pagingData)
    }
}

private fun DocumentSnapshot.createDataMap(spec: FirebaseFirestoreLoaderSpec): Map<String, Any> {
    return spec.dataValues!!.associate { dataValue ->
        val value: Any = if(contains(dataValue.key)) {
            when(dataValue) {
                is ValueDataSpec -> when (dataValue.valueType) {
                    ValueDataSpec.Type.Timestamp -> {
                        val timestamp = get<Timestamp>(dataValue.key)
                        Instant.fromEpochSeconds(timestamp.seconds).toString()
                    }
                    else -> get<String>(dataValue.key)
                }
                is ListDataSpec -> {
                    // Only supports string arrays right now need to add all
                    get<List<String>>(dataValue.key).map {
                        mapOf(stringArrayItemName to it)
                    }
                }
                else -> ""
            }

        } else ""
        dataValue.key to value
    }.plus(FirebaseFirestoreLoaderSpec.documentId to id)
}
