package com.appcreator.blueprint.conditions.data

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.DataOptionsFrom
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.properties.DataValueSet
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ContainsItem")
@ConditionClass(
    group = "Data",
    title = "List Contains Item",
    availableFrom = "0.0.1"
)
data class ContainsItemCondition(

    @InputProperty(
        title = "List",
        availableFrom = "0.0.1",
        dataOptions = DataSpecType.List
    )
    val listPath: DataPath?,
    @InputProperty(
        title = "Where equals",
        description = "Leave value blank to match any",
        availableFrom = "0.0.1"
    )
    @DataOptionsFrom("listPath")
    val values: DataValueSet?

): Condition
