package com.appcreator.compose

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.conditions.permission.CheckPermissionCondition

@Composable
actual fun getPermissions(): State<Map<String, String>?> = mutableStateOf(
    CheckPermissionCondition.Permission.entries.associate {
        "permission-state-${it.name}" to CheckPermissionCondition.State.Granted.name
    }
)