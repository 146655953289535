package com.appcreator.compose.extensions

import androidx.compose.runtime.Composable

actual suspend fun evaluateJavaScript(config: JSEvaluationConfig, script: String): String? {
    return evaluateJavaScriptSync(script)
}

actual fun evaluateJavaScriptSync(script: String): String? {
    return try {
        // TODO create sandbox to run this is, very unlikely but in theory a bad actor could inject fishing JS
        // then be like helpdesk can you check my issue to get the builder code to run on someone else's computer
        eval(script).toString()
    } catch (ex: Throwable) {
        println(script)
        ex.printStackTrace()
        null
    }
}

actual class JSEvaluationConfig

@Composable
actual fun defaultJSEvaluationConfig(): JSEvaluationConfig = JSEvaluationConfig()