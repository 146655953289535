package com.appcreator.compose.loaders

import androidx.compose.runtime.ProvidedValue
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.loaderspec.JsonLoaderSpec
import com.appcreator.compose.api.JsonFetcher
import kotlinx.datetime.Instant

class JsonLoader(
    private val fetcher: JsonFetcher,
    private val loaderSpec: JsonLoaderSpec,
): Loader {

    override suspend fun load(
        envStore: EnvStore,
        mapping: Map<String, String>,
        setPageLastUpdated: ((Instant) -> Unit)?
    ): Pair<ProvidedValue<*>?, Map<String, Any>> {
        val updated = fetcher.fetchJsonIntoEnv(envStore, loaderSpec, mapping)
        return null to updated
    }

    override fun canLoadMore(data: Map<String, Any>): Boolean = false
}