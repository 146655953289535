package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("UUIDConversion")
@ConversionClass(
    title = "Random UUID",
    availableFrom = "0.0.1"
)
class UUIDConversion: Conversion
