package com.appcreator.client.firebase.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoggedInCondition
import com.appcreator.compose.conditions.Evaluator
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import kotlinx.coroutines.flow.first

class LoggedInEvaluator(condition: FirebaseAuthLoggedInCondition): Evaluator {
    @Composable
    override fun evaluate(envStore: EnvStore): Boolean {
        val user by Firebase.auth.authStateChanged.collectAsState(Firebase.auth.currentUser)
        return user != null
    }

    override fun evaluateOnce(envStore: EnvStore): Boolean? {
        return Firebase.auth.currentUser != null
    }

    override suspend fun evaluateAsync(envStore: EnvStore): Boolean {
        return Firebase.auth.authStateChanged.first() != null
    }

}
