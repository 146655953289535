package com.appcreator.compose.actions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.TriggerAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalTriggerBus

class TriggerPerformer(private val action: TriggerAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val triggerBus = LocalTriggerBus.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.triggerId?.let {
                    triggerBus.trigger(it)
                }
            }
        }
    }
}