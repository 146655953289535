package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ImageResource
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.SizeValue

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Image")
@ComponentClass(
    group = "Basic",
    title = "Image",
    availableFrom = "0.0.1"
)
data class ImageComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Source",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ImageComponent.Source.ImageCenter")
    val source: Source?,

    @InputProperty(
        title = "Image Resource",
        availableFrom = "0.0.1"
    )
    @DisplayIf("source.value == ImageComponent.Source.ImageCenter.name")
    val imageResource: ImageResource?,

    @InputProperty(
        title = "URL",
        availableFrom = "0.0.1"
    )
    @DisplayIf("source.value == ImageComponent.Source.URL.name")
    val url: LocalizableString?,

    @InputProperty(
        title = "Scale",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ImageComponent.Scale.Default")
    val scale: Scale?,

    @InputProperty(
        section = "Layout",
        title = "Width",
        availableFrom = "0.0.1"
    )
    val width: SizeValue?,
    @InputProperty(
        section = "Layout",
        title = "Height",
        availableFrom = "0.0.1"
    )
    val height: SizeValue?,

    @InputProperty(
        section = "Progress",
        title = "Loading",
        availableFrom = "0.0.1"
    )
    val loading: Component?,

    @InputProperty(
        section = "Progress",
        title = "Fallback",
        availableFrom = "0.0.1"
    )
    val fallback: Component?

): Component {

    enum class Source {
        ImageCenter,
        URL
    }

    enum class Scale {
        Default,
        None,
        Crop,
        Fit,
        FillHeight,
        FillWidth,
        Inside,
        FillBounds
    }

}