package com.appcreator.blueprint.components

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.properties.UserDefinedComponent
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.SkipSwiftGeneration
import com.appcreator.blueprint.core.properties.SizeValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put

@Serializable
@SerialName("CustomComponent")
@SkipSwiftGeneration
@ComponentClass(
    group = "Other",
    title = "Custom",
    availableFrom = "0.0.1",
    accessible = false // TODO re-add at some point
)
data class CustomComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        section = "Preview",
        title = "Style",
        availableFrom = "0.0.1"
    )
    @GenericDefault("CustomComponent.PreviewStyle.Size")
    val previewStyle: PreviewStyle?,

    @InputProperty(
        section = "Preview",
        title = "Width",
        availableFrom = "0.0.1"
    )
    @DisplayIf("previewStyle.value == CustomComponent.PreviewStyle.Size.name")
    @GenericDefault("com.appcreator.blueprint.core.properties.SizeValue.Percent(100)")
    val width: SizeValue?,

    @InputProperty(
        section = "Preview",
        title = "Height",
        availableFrom = "0.0.1"
    )
    @DisplayIf("previewStyle.value == CustomComponent.PreviewStyle.Size.name")
    @GenericDefault("com.appcreator.blueprint.core.properties.SizeValue.Px(400)")
    val height: SizeValue?,

    @InputProperty(
        section = "Preview",
        title = "Component",
        availableFrom = "0.0.1"
    )
    @DisplayIf("previewStyle.value == CustomComponent.PreviewStyle.Component.name")
    val previewComponent: Component?,

    @InputProperty(
        section = "Component",
        title = "Type",
        availableFrom = "0.0.1"
    )
    val userDefinedComponent: UserDefinedComponent?,

): Component {

    val content: JsonObject? = userDefinedComponent?.let {
        buildJsonObject {
            put("_nodeId", it.id)
            put("type", "user-defined-${it.name}")
        }
    }

    enum class PreviewStyle {
        Component,
        Size,
        Hidden
    }
}

@Serializable
@SerialName("CustomComponent")
@ComponentClass(
    group = "Other",
    title = "Custom",
    availableFrom = "0.0.1",
    accessible = false
)
data class CustomClientComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Preview Style",
        availableFrom = "0.0.1"
    )
    @GenericDefault("CustomClientComponent.PreviewStyle.Size")
    val previewStyle: PreviewStyle?,
    @InputProperty(
        title = "Width",
        availableFrom = "0.0.1"
    )
    val width: SizeValue?,
    @InputProperty(
        title = "Height",
        availableFrom = "0.0.1"
    )
    val height: SizeValue?,
    @InputProperty(
        title = "Preview Content",
        availableFrom = "0.0.1"
    )
    val previewComponent: Component?,

    @InputProperty(
        title = "User Defined Component",
        availableFrom = "0.0.1"
    )
    val userDefinedComponent: UserDefinedComponent?,

    @InputProperty(
        title = "Content",
        availableFrom = "0.0.1"
    )
    val content: Component?

): Component {
    enum class PreviewStyle {
        Component,
        Size,
        Hidden
    }
}