package com.appcreator.blueprint.firebase.auth

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseAuthSetDisplayName")
@ActionClass(
    group = FirebaseAuthConsts.group,
    title = "Set Display Name",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseAuthSetDisplayNameAction(
    @InputProperty(
        title = "Display Name",
        availableFrom = "0.0.1"
    )
    val displayName: String?,
): Action
