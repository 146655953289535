package com.appcreator.compose.conversions

import com.appcreator.blueprint.conversions.DateAdditionConversion
import com.appcreator.blueprint.core.EnvStore
import kotlinx.datetime.Instant
import kotlinx.serialization.json.JsonElement
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

data class DateAdditionConvertor(
    val conversion: DateAdditionConversion
): Convertor {

    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String? {
        with(conversion) {
            val date = envStore.env[datePath?.value] as? String ?: return null
            var instant = try {
                Instant.parse(date)
            } catch (ex: Exception) {
                return null
            }

            seconds?.toIntOrNull(envStore)?.seconds?.let {
                instant = instant.plus(it)
            }
            minutes?.toIntOrNull(envStore)?.minutes?.let {
                instant = instant.plus(it)
            }
            hours?.toIntOrNull(envStore)?.hours?.let {
                instant = instant.plus(it)
            }
            days?.toIntOrNull(envStore)?.days?.let {
                instant = instant.plus(it)
            }

            return instant.toString()
        }
    }

    private fun String.toIntOrNull(envStore: EnvStore): Int? {
        return envStore.injectVariables(this).toIntOrNull()
    }

}
