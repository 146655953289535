package com.appcreator.compose.extensions

import androidx.compose.runtime.Composable
import androidx.compose.ui.text.intl.Locale
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.compose.LocalEnvStore

@Composable
fun LocalizableString.value(): String {
    return LocalEnvStore.current.injectLocalizableString(this, Locale.current.toLanguageTag())
}
