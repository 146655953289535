package com.appcreator.compose.conditions.permission

import com.appcreator.blueprint.conditions.permission.CheckPermissionCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator

class CheckPermissionEvaluator(private val condition: CheckPermissionCondition) : Evaluator {

    override fun evaluateOnce(envStore: EnvStore): Boolean {
        val state = condition.permission?.let { envStore.injectVariables("permission-state-${it.name}") }
        return state == condition.state?.name
    }


}