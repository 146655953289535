package com.appcreator.blueprint.actions.navigation

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("OpenExternal")
@ActionClass(
    group = "Navigation",
    title = "Open External Link",
    availableFrom = "0.0.1"
)
class OpenExternalAction(
    @InputProperty(
        title = "Link",
        availableFrom = "0.0.1"
    )
    val link: String?,
): Action
