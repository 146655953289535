package com.appcreator.compose.api

import com.appcreator.blueprint.core.UrlSpec
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.header
import io.ktor.client.request.request
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.jsonObject

suspend inline fun HttpClient.requestJson(urlSpec: UrlSpec): JsonElement {
    val json: JsonElement = request(urlSpec)
    return if (urlSpec.verb == UrlSpec.Verb.Post && urlSpec.bodyType == UrlSpec.BodyType.GraphQl) {
        json.jsonObject["data"]!!
    } else {
        json
    }
}

suspend inline fun <reified T> HttpClient.request(urlSpec: UrlSpec): T {
    return request(urlSpec.url) {
        method = urlSpec.verb.method()
        url {
            urlSpec.queries.forEach { (key, value) ->
                parameters.append(key, value)
            }
        }
        urlSpec.headers.forEach { (key, value) ->
            header(key, value)
        }

        if ((urlSpec.verb == UrlSpec.Verb.Post
                    || urlSpec.verb == UrlSpec.Verb.Put)
            && urlSpec.body.isNotBlank()
        ) {
            when(urlSpec.bodyType) {
                UrlSpec.BodyType.Json -> {
                    contentType(ContentType.Application.Json)
                    setBody(urlSpec.body)
                }
                UrlSpec.BodyType.GraphQl -> {
                    val json = buildJsonObject { put("query", JsonPrimitive(urlSpec.body)) }
                    contentType(ContentType.Application.Json)
                    setBody(json)
                }
                UrlSpec.BodyType.Form -> {
                    TODO()
                }
            }
        }
    }.body()
}

fun UrlSpec.Verb.method(): HttpMethod =
    when(this) {
        UrlSpec.Verb.Get -> HttpMethod.Get
        UrlSpec.Verb.Post -> HttpMethod.Post
        UrlSpec.Verb.Put -> HttpMethod.Put
        UrlSpec.Verb.Delete -> HttpMethod.Delete
    }