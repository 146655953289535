package com.appcreator.client.firebase.auth

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.auth.FirebaseAuthResetPasswordAction
import com.appcreator.compose.actions.Performer
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth

class ResetPasswordPerformer(val action: FirebaseAuthResetPasswordAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred =
        object: Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                val email =  action.email?.let { envStore.injectVariables(it) }?: throw IllegalStateException("No email")
                Firebase.auth.sendPasswordResetEmail(email)
            }
        }
}