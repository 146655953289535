package com.appcreator.client.firebase.remoteconfig

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConversion
import com.appcreator.compose.conversions.Convertor
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.remoteconfig.remoteConfig
import kotlinx.serialization.json.JsonElement

class RemoteConfigConvertor(private val conversion: FirebaseRemoteConfigConversion): Convertor {

    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String? {
            return conversion.keyName?.let {
                val name = envStore.injectVariables(it)
                Firebase.remoteConfig.getValue(name).toString()
            }
    }
}