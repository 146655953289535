package com.appcreator.blueprint.firebase.firestore

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.TypedDataValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseFirestoreDeleteData")
@ActionClass(
    group = FirebaseFirestoreConsts.group,
    title = "Delete Data",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseFirestoreDeleteDataAction(
    @InputProperty(
        title = "Collection Path",
        availableFrom = "0.0.1"
    )
    val path: String?,
): Action
