package com.appcreator.compose.components.basic

import androidx.compose.foundation.Image
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import coil3.compose.AsyncImagePainter
import coil3.compose.LocalPlatformContext
import coil3.compose.SubcomposeAsyncImage
import coil3.compose.rememberAsyncImagePainter
import coil3.request.ImageRequest
import coil3.size.Size
import com.appcreator.blueprint.components.basic.ImageComponent
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.compose.LocalResources
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.SizingView
import com.appcreator.compose.extensions.value

@Composable
fun ImageComposable(modifier: Modifier, component: ImageComponent) {
    ImageComposable(modifier, component, null)
}
@Composable
fun ImageComposable(modifier: Modifier, component: ImageComponent, colorFilter: ColorFilter?) {
    
    val imageUrl = when(component.source) {
        ImageComponent.Source.ImageCenter -> {
            component.imageResource?.let {
                val resources = LocalResources.current
                resources("drawable/${it.id}.${it.fileExtension}")?: platformCorsWorkAround(it.url)
            }
        }
        else -> component.url?.value()?.let { platformCorsWorkAround(it) }
    }

    SizingView(component.width, component.height) { mod, hasWidth, hasHeight ->
        val contentScale = when(component.scale) {
            ImageComponent.Scale.None -> ContentScale.None
            ImageComponent.Scale.Crop -> ContentScale.Crop
            ImageComponent.Scale.Fit -> ContentScale.Fit
            ImageComponent.Scale.FillHeight -> ContentScale.FillHeight
            ImageComponent.Scale.FillWidth -> ContentScale.FillWidth
            ImageComponent.Scale.Inside -> ContentScale.Inside
            ImageComponent.Scale.FillBounds -> ContentScale.FillBounds
            else -> when {
                hasWidth && !hasHeight -> ContentScale.FillWidth
                hasHeight && !hasWidth -> ContentScale.FillHeight
                else -> ContentScale.Fit
            }
        }
        imageUrl?.let { url ->
            SubcomposeAsyncImage(
                modifier = mod.then(modifier),
                model = ImageRequest.Builder(LocalPlatformContext.current)
                    .data(url)
                    .size(Size.ORIGINAL)
                    .build(),
                success = { success ->
                    PlatformImage(Modifier, contentScale, success) {
                        Image(
                            modifier = it,
                            painter = success.painter,
                            contentDescription = null,
                            contentScale = contentScale,
                            colorFilter = colorFilter
                        )
                    }
                },
                loading = {
                    component.loading?.let { loading ->
                        ComponentComposable(Modifier, loading)
                    }
                },
                error = {
                    component.fallback?.let { fallback ->
                        ComponentComposable(Modifier, fallback)
                    }
                },
                contentScale = contentScale,
                contentDescription = null,
            )
        }
    }
}

expect fun platformCorsWorkAround(
    value: String
): String

@Composable
expect fun PlatformImage(
    modifier: Modifier,
    scale: ContentScale,
    success: AsyncImagePainter.State.Success,
    default: @Composable (Modifier) -> Unit
)

@Composable
fun Modifier.backgroundImage(
    url: LocalizableString?,
    opacity: String?
): Modifier {
    url?.value()?.let { localizedUrl ->
        val painter: Painter = rememberAsyncImagePainter(localizedUrl, contentScale = ContentScale.Crop)
        return drawBehind {
            with(painter) {
                draw(size, alpha = opacity?.toFloatOrNull()?: 1f)
            }
        }
    }
    return this
}