package com.appcreator.client.revenuecat

import com.appcreator.blueprint.revenuecat.RevenuecatHasEntitlementCondition
import com.appcreator.blueprint.revenuecat.RevenuecatManageSubscriptionsAction
import com.appcreator.blueprint.revenuecat.RevenuecatPaywallComponent
import com.appcreator.compose.di.Container


object RevenueCatInitializer {
    fun initialize(
        androidApiKey: String,
        iOSApiKey: String
    ) {
        Container.registerComponent(RevenuecatPaywallComponent::class) { mod, comp ->
            PaywallComposable(mod, comp)
        }
        Container.registerCondition(RevenuecatHasEntitlementCondition::class) {
            HasEntitlementEvaluator(it)
        }
        Container.registerAction(RevenuecatManageSubscriptionsAction::class) {
            ManageSubscriptionsPerformer(it)
        }
        revenueCatInitialize(androidApiKey, iOSApiKey)
    }
}

expect fun revenueCatInitialize(androidApiKey: String, iOSApiKey: String)
