package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class ImageResource(
    val id: String,
    val fileExtension: String?,
    val name: String,
    val url: String
)