package com.appcreator.client.firebase.analytics

import com.appcreator.compose.di.Container
import com.appcreator.compose.interfaces.Analytics
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.FirebaseAnalyticsEvents
import dev.gitlive.firebase.analytics.FirebaseAnalyticsParam
import dev.gitlive.firebase.analytics.analytics

private class FirebaseAnalytics: Analytics {

    override fun setUserId(userId: String?) {
        Firebase.analytics.setUserId(userId)
    }

    override fun screenView(screenPath: String, screenName: String?) {
        event(FirebaseAnalyticsEvents.SCREEN_VIEW, mapOf(
            "screen_path" to screenPath,
            FirebaseAnalyticsParam.SCREEN_NAME to (screenName?: screenPath)
        ))
    }

    override fun event(name: String, params: Map<String, String>) {
        Firebase.analytics.logEvent(name, params)
        println("$name $params")
    }
}

object FirebaseAnalyticsInitializer {
    fun initialize() {
        Container.registerAnalytics(FirebaseAnalytics())
    }
}
