package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.SizeValue

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Video")
@ComponentClass(
    group = "Basic",
    title = "Video",
    availableFrom = "0.0.1"
)
data class VideoComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "URL",
        availableFrom = "0.0.1"
    )
    val url: LocalizableString?,

    @InputProperty(
        section = "Layout",
        title = "Aspect Ratio",
        availableFrom = "0.0.1"
    )
    @GenericDefault("1.78f")
    val aspectRatio: Float?,

    @InputProperty(
        section = "Data",
        title = "Is Full Screen",
        availableFrom = "0.0.1",
        description = "Value to update when player goes into full screen",
        allowDataFields = false,
        settableDataOnly = true
    )
    val isFullscreen: DataPath?,

    @InputProperty(
        section = "Data",
        title = "Controls Visible",
        availableFrom = "0.0.1",
        description = "Value to update when controls are shown/hidden",
        allowDataFields = false,
        settableDataOnly = true
    )
    val areControlsVisible: DataPath?

): Component